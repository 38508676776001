import React, {useEffect, useState} from 'react';
import H1 from "../Components/Typography/Heading/H1";
import H2 from "../Components/Typography/Heading/H2";
import {Box, Button, Checkbox, Container, Flex, Icon, Image,} from "@chakra-ui/react";
import H3 from "../Components/Typography/Heading/H3";
import Pxl from "../Components/Typography/Text/Pxl";
import Plg from "../Components/Typography/Text/Plg";
import Pmd from "../Components/Typography/Text/Pmd";
import Psm from "../Components/Typography/Text/Psm";
import ButtonPrimary from "../Components/Button/ButtonPrimary";
import ButtonSecondary from "../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../Components/Button/ButtonCheckboxPrimary";
import ButtonCheckboxSecondary from "../Components/Button/ButtonCheckboxSecondary";
import InputOutline from "../Components/Input/InputOutline";
import InputPassword from "../Components/Input/InputPassword"
import {PiFunnelLight} from "react-icons/pi";
import moment from "moment";
import RoomCard from "../Components/RoomCard";
import axios from "axios";
import {NEW_API_URL} from "../redux/types";
import {useDispatch, useSelector} from "react-redux";
import CreateRoomModal from "../Components/CreateRoomModal";
import ParticipantListModal from "../ParticipantListModal";
import {getUserWorkspaces} from "../redux/actions/userActions";

function Room(props) {

    // const [participants,setParticipants] = useState([])
    // const [blockList,setBlocklist] = useState([])
    // const [reportList,setReportlist] = useState([])
    // const [pendingList,setPendinglist] = useState([])
    // const [forceRefetch,setForceRefetch] = useState(0)
    // const [modalVisible, setModalVisible] = useState(false);
    // const [selectedUser, setSelectedUser] = useState("");
    // const [reportReason, setReportReason] = useState("");
    const currentWorkspace = useSelector((state) => state.user.selectedWorkspace);
    const username = useSelector((state)=>state.user.username)
    const rooms = useSelector((state) => state.user?.selectedWorkspace?.rooms);
    const [roomDetailData, setRoomDetailData] = React.useState([]);

    console.log("SRTAE",rooms)
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenParticipant, setIsOpenParticipant] = useState(false);
    const [openRoom, setOpenRoom] = useState(0);
    const [participantsData,setparticipantsData] = useState([{
        title: "Participants",
        entries: [],
    },
        {
            title: "Blocked",
            entries: [],
        },
        {
            title: "Reported",
            entries: [],
        },
        {
            title: "Pending",
            entries: [],
        },])


    useEffect(() => {
        if (rooms && rooms.length > 0) {
            const f = async (promises) => {
                const responses = await Promise.all(promises);
                const mapped = responses.map((i) => i.data.room);
                setRoomDetailData(mapped);
            };
            const promises = rooms.map((room) => {
                // console.log(
                //   API_URL +
                //     "/community/get_room/" +
                //     currentWorkspace.workspace_id +
                //     "/" +
                //     room.id +
                //     "/"
                // );
                return axios.get(
                    NEW_API_URL +
                    "/community/get_room/" +
                    currentWorkspace.workspace_id +
                    "/" +
                    room.id +
                    "/"
                );
            });
            f(promises)
                .then((r) => {})
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [rooms]);



    const remove_from_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        const elem = participantsData[idx]
        const nelem = elem.entries.filter(i=>i !== entry)
        current_participants[idx] = {
            title:title,
            entries : nelem
        }
        return current_participants
    }

    const append_to_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        const elem = participantsData[idx]
        const nelem = elem.entries.concat(entry)
        current_participants[idx] = {
            title:title,
            entries:nelem
        }
        return current_participants
    }

    const set_to_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        // const elem = participantsData[idx]
        // const nelem = elem.entries.push(entry)
        current_participants[idx] = {
            title:title,
            entries:entry
        }
        return current_participants
    }

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // const getRoomDataForRoomId = (id) => {
    //     // console.log("ROOM ID CALLED WITH ",id)
    //     return roomDetailData.find((i) => i.id === id);
    // };
    //
    // const isParticipantOfRoom = (username, roomid) => {
    //     const data = getRoomDataForRoomId(roomid);
    //     if (data && data.participants_list && data.participants_list.length > 0) {
    //         for (let i = 0; i < data.participants_list.length; i++) {
    //             if (data.participants_list[i] === username) return true;
    //         }
    //     }
    //     return false;
    // };

    // useEffect(()=>{
    //     // if(room){
    //         axios.get(NEW_API_URL+"community/get_room/"+currentWorkspace.workspaceId+"/"+1+"/").then(r=>{
    //             console.log(r.data)
    //             // setBlocklist(r.data.room.block_list||[])
    //             // setReportlist(r.data.room.report_list||[])
    //             // setPendinglist(r.data.room.pending_list||[])
    //         }).catch(err=>{
    //             console.log(err)
    //         })
    //     // }
    // },[])

    return (
        <div>
            <div style={{display:'flex',margin:20}}>
                <ButtonSecondary onClick={onOpen}>Create Room</ButtonSecondary>
                <CreateRoomModal isOpen={isOpen} onClose={onClose} />
                <ParticipantListModal
                    openRoom={openRoom}
                    isOpen={isOpenParticipant}
                    onClose={()=>{setIsOpenParticipant(false)}}
                    participantsData={participantsData}
                    username={username}
                />
            </div>

        <div style={{display:'flex',flexWrap:'wrap'}}>
            {
                currentWorkspace.rooms.map((item,idx)=>(
                    <RoomCard  onClickJoin={()=>{

                        axios
                            .post(
                                NEW_API_URL +
                                "community/add_to_pending/" +
                                currentWorkspace.workspace_id +
                                "/" +
                                item.id +
                                "/" +
                                username +
                                "/",
                                {
                                    user_to_add: username,
                                }
                            )
                            .then((r) => {
                                console.log("added!!");
                            })
                            .catch((err) => {
                                console.log(err);
                            });


                        if(window.confirm("You will be joining a video call")){
                            window.location.href="/room/"+currentWorkspace.workspace_id+"/"+item.id
                        }
                    }} onClickParticipant={()=>{

                        axios.get(NEW_API_URL+"community/get_room/"+currentWorkspace.workspace_id+"/"+item.id+"/").then(r=>{
                                        console.log(r.data)
                                        const n1 = set_to_participants(participantsData,"Blocked",r.data.room.block_list||[])
                                        const n2 = set_to_participants(n1,"Reported",r.data.room.report_list||[])
                                        const n3 = set_to_participants(n2,"Pending",r.data.room.pending_list||[])
                                        // setBlocklist(r.data.room.block_list||[])
                                        // setReportlist(r.data.room.report_list||[])
                                        // setPendinglist(r.data.room.pending_list||[])
                                        setparticipantsData(n3)
                                        setOpenRoom(item.id)
                                        setIsOpenParticipant(true)

                                    }).catch(err=>{
                                        console.log(err)
                                    })
                    }} name={item.name} id={item.id} key={item.id} private={item.is_private}/>
                ))
            }
        </div>
        </div>

    );
}

export default Room;
