import React, { useState, useEffect, useRef } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Tag,
  Textarea,
  useDisclosure,
  useSteps,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL, NEW_API_URL_DEV } from "../../redux/types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import Markdown from "react-markdown";
import { HighlightMenu, setClipboard } from "react-highlight-menu";
import ButtonText from "../../Components/Button/ButtonText";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import AskBudAccent from "../../Components/AskBudAccent";
import BackArrow from "../../Components/backArrow";
import conceptData from "../../../src/data/dummyConceptDetails.json";
import CustomStepper from "../../Components/CustomStepper";
import ConceptReader from "../../Components/ConceptReader";

const ConceptReading = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const username = useSelector((state) => state.user.username);

  const [selectedText, setSelectedText] = useState("");
  const [quickexpText, setQuickExpText] = useState("");
  const [isExplainationLoading, setIsExplainationLoading] = useState(false);
  const [concepts, setConcepts] = useState([]);
  const [notesTextData, setNotesTextData] = useState({});
  const menuRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const notesModalStates = useDisclosure();
  const { topic, subject, area, noteid, indext } = useParams();
  const mynote = searchParams.get("mynote"); // 'myParam' is the key of the query param
  const [activeConcept, setActiveConcept] = useState(0);

  const newUser = useSelector((state) => state.user.newUser);

  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = "";
  if (selectedWorkspace && selectedWorkspace.hasOwnProperty("workspace_id")) {
    workspaceId = selectedWorkspace.workspace_id;
  }
  const [topicDetail, setTopicDetail] = useState({});

  const get_map_data = async (noteid) => {
    try {
      const res = await axios.get(
        NEW_API_URL + "lessonplan/get-concept-details" + "/" + noteid
      );

      setConceptDetail(res.data);
    } catch (error) {
      console.log("Error in fetching cocept details for id:", noteid);
    }
  };

  useEffect(() => {
    get_map_data(noteid);
  }, []);

  // useEffect(() => {
  //     if (noteid)
  //         axios
  //             .post(NEW_API_URL + "lessonplan/update_activity/", {
  //                 user_id: username,
  //                 topic_id: topic,
  //                 activity_type: "concept",
  //                 activity_id: noteid,
  //                 read_status: "Read",
  //             })
  //             .then((r) => {
  //             });
  // }, []);

  useEffect(() => {
    if (indext) get_topic_detail();
  }, [indext]);

  const get_topic_detail = async () => {
    let cp = concepts;
    if (concepts.length <= 0) {
      if (topic === "mynote") {
        const r = await axios.get(
          NEW_API_URL + "notes/get_note_list/" + username
        );
        const arr = r.data;
        setMaxPages(r.data.length);
        setConcepts(arr);
        cp = arr;
      } else {
        const r = await axios
          .get(NEW_API_URL + "lessonplan/get-topic-materials/" + topic + "/")
          .catch((err) => {});
        const arr = r.data.topic_detail?.notes;
        setMaxPages(r.data.topic_detail?.notes.length);
        setConcepts(arr);
        cp = arr;
      }
    }

    if (indext < cp.length) {
      axios
        .get(
          NEW_API_URL +
            "notes/get_note/" +
            "admin@claydeck.com/" +
            "/" +
            cp[indext].id +
            "/"
        )
        .then((r) => {
          setNotesTextData(r.data);
          setNoteDetail({
            pages: [r.data.description],
            qa: [],
          });
          // setMa
        })
        .catch((err) => {});

      // axios
      //   .post(NEW_API_URL + "lessonplan/update_activity/", {
      //     user_id: username,
      //     topic_id: topic,
      //     activity_type: "concept",
      //     activity_id: cp[indext].id,
      //     read_status: "Read",
      //   })
      //   .then((r) => {})
      //   .catch((err) => {});
    }
  };

  // useEffect(() => {
  //   if (noteid)
  //     axios
  //       .get(NEW_API_URL + "notes/get_note/admin@claydeck.com/" + noteid + "/")
  //       .then((r) => {
  //         setNotesTextData(r.data);

  //         setNoteDetail({
  //           pages: [r.data.description],
  //           qa: [],
  //         });
  //         // setMa
  //       });
  //   if (indext) {
  //     get_topic_detail();
  //   }
  // }, []);

  const [page, setPage] = useState(0);
  const [forceOpen, setForceOpen] = useState(false);
  const [initText, setInitText] = useState("");
  const [maxPages, setMaxPages] = useState(0);
  const [adminUser, setAdminUser] = useState(false);
  const [resize, setResize] = useState("horizontal");

  // const [noteDetail, setNoteDetail] = useState({
  //   pages:['PG1'+markdown_str,'PG2'+markdown_str,'PG3'+markdown_str],
  //   qa:[{question:'aaaa',answer:'test'},{question:'bbbb',answer:'aa'}]
  // });
  //   const steps = [
  //       { title: 'First', description: 'Contact Info' },
  //       { title: 'Second', description: 'Date & Time' },
  //       { title: 'Third', description: 'Select Rooms' },
  //   ]
  //   const { activeStep } = useSteps({
  //       index: 0,
  //   })
  const [noteDetail, setNoteDetail] = useState({ pages: [], qa: [] });
  const [conceptDetail, setConceptDetail] = useState();

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex mt={"10vh"} alignItems={"center"}>
              <BackArrow
                onClick={() => {
                  navigate(-1, { replace: true });
                }}
              />

              <Box>
                <P2xl mb={2} ml={0} mr={0}>
                  <strong>
                    {conceptDetail?.title ? conceptDetail?.title : "Concept"}
                  </strong>
                </P2xl>
                {/* <Pmd color={"#817D7D"} style={{ margin: 0 }}>
                  <strong>
                    {conceptDetail.topic ? conceptDetail.topic : "Topic Name"}
                  </strong>
                </Pmd> */}
              </Box>
            </Flex>
            {/* <hr/> */}
            <Box
              background={"#FFFFFF"}
              borderRadius={"16px"}
              pt={2}
              pl={4}
              pr={4}
              mb={1}
              mt={4}
              border={"1px solid #E2E8F0"}
              shadow={"md"}
            >
              <CustomStepper
                conceptDetail={conceptDetail}
                activeConcept={activeConcept}
                setActiveConcept={setActiveConcept}
              />
            </Box>

            <ConceptReader
              isLast={() => {
                // navigate(-1)
                onOpen();
              }}
              conceptDetail={conceptDetail}
              activeConcept={activeConcept}
              setActiveConcept={setActiveConcept}
            />
          </Box>
        </Flex>

        {/*<HighlightMenu*/}
        {/*    styles={{*/}
        {/*        backgroundColor: "#344675",*/}
        {/*        borderRadius: "16px",*/}
        {/*    }}*/}
        {/*    menu={({selectedText, setMenuOpen, setClipboard}) => {*/}
        {/*        return (*/}
        {/*            <Flex bg={"#344675"} padding={0} borderRadius={"30px"}>*/}
        {/*                <ButtonText*/}
        {/*                    fontWeight={"300"}*/}
        {/*                    onClick={() => {*/}
        {/*                        setQuickExpText("");*/}
        {/*                        setSelectedText(selectedText);*/}
        {/*                        onOpen();*/}
        {/*                        setMenuOpen(false);*/}
        {/*                        const payload = {*/}
        {/*                            action: "doubt",*/}
        {/*                            phrase: selectedText,*/}
        {/*                            context:*/}
        {/*                                "You are context giver bot for explaining the phrase or word",*/}
        {/*                        };*/}
        {/*                        setIsExplainationLoading(true);*/}
        {/*                        axios*/}
        {/*                            .post(*/}
        {/*                                NEW_API_URL +*/}
        {/*                                "gamification/instant_doubt_clarification/",*/}
        {/*                                payload*/}
        {/*                            )*/}
        {/*                            .then((r) => {*/}
        {/*                                setQuickExpText(r.data.message);*/}
        {/*                                setIsExplainationLoading(false);*/}
        {/*                                // setChat((prev)=>[...prev,{'from':'bud',text:r.data.message,time:new Date().toISOString()}])*/}
        {/*                            });*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    Quick Explanation*/}
        {/*                </ButtonText>*/}
        {/*                <Box border={"0.5px solid white"} h={"100%"}/>*/}

        {/*                <ButtonText*/}
        {/*                    fontWeight={"300"}*/}
        {/*                    onClick={() => {*/}
        {/*                        setForceOpen(true);*/}
        {/*                        setInitText(*/}
        {/*                            "You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " +*/}
        {/*                            selectedText*/}
        {/*                        );*/}
        {/*                        setSelectedText(selectedText);*/}
        {/*                        // notesModalStates.onOpen()*/}
        {/*                        setMenuOpen(false);*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    Ask Question*/}
        {/*                </ButtonText>*/}
        {/*                <Box border={"0.5px solid white"} h={"100%"}/>*/}
        {/*                <ButtonText*/}
        {/*                    fontWeight={"300"}*/}
        {/*                    onClick={() => {*/}
        {/*                        setSelectedText(selectedText);*/}
        {/*                        notesModalStates.onOpen();*/}
        {/*                        setMenuOpen(false);*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    Add to Note*/}
        {/*                </ButtonText>*/}
        {/*            </Flex>*/}
        {/*        );*/}
        {/*    }}*/}
        {/*    target={menuRef}*/}
        {/*/>*/}
        {/*      <div ref={menuRef}>*/}
        {/*  Selecting this text will show the menu!*/}
        {/*</div>*/}

        {/*<CustomStyledModal*/}
        {/*    quick_exp={quickexpText}*/}
        {/*    quick_explain*/}
        {/*    isOpen={isOpen}*/}
        {/*    onOpen={onOpen}*/}
        {/*    onClose={onClose}*/}
        {/*    title={"Quick Explanation"}*/}
        {/*    selectedText={selectedText}*/}
        {/*    isLoading={isExplainationLoading}*/}
        {/*/>*/}
        {/*<CustomStyledModal*/}
        {/*    add_type_topic={true}*/}
        {/*    force_rel={"note"}*/}
        {/*    force_topic={notesTextData.topic}*/}

        {/*    topicDetail={topicDetail}*/}
        {/*    area={notesTextData.area}*/}
        {/*    subject={notesTextData.subject}*/}
        {/*    topic_name={notesTextData.topic}*/}
        {/*    topic_id={topic}*/}
        {/*    toast={*/}
        {/*        "Note successfully created! You can view it on the Notes page in this topic"*/}
        {/*    }*/}
        {/*    add_note*/}
        {/*    isOpen={notesModalStates.isOpen}*/}
        {/*    onOpen={notesModalStates.onOpen}*/}
        {/*    onClose={notesModalStates.onClose}*/}
        {/*    title={"Notes"}*/}
        {/*    selectedText={selectedText}*/}
        {/*/>*/}
        {/*<AskBudAccent*/}
        {/*    context={`Give answers related to ${selectedText} in the context of ${notesTextData.topic}`}*/}
        {/*    forceOpen={forceOpen}*/}
        {/*    selectedText={selectedText}*/}
        {/*    setForceOpen={setForceOpen}*/}
        {/*    initText={notesTextData?.title}*/}
        {/*/>*/}
        <CustomStyledModal
          isOpen={isOpen}
          onClose={onClose}
          conceptReader={
            conceptDetail?.title ? conceptDetail?.title : "Concept Name"
          }
        />
      </Container>
    </>
  );
};

export default ConceptReading;
