import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import AskBudAccent from "../../Components/AskBudAccent";
import { useParams } from "react-router-dom";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
import BackArrow from "../../Components/backArrow";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const TopicDetail = () => {
  const { topic, subject, area } = useParams();
  console.log(topic, "topic");
  const navigate = useNavigate();
  const newUser = useSelector((state) => state.user.newUser);
  const username = useSelector((state) => state.user.username);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = 1;
  const [cardProgress, setCardsProgress] = useState({});

  const [topicDetail, setTopicDetail] = useState({});
  const [subjectData, setSubjectData] = useState([]);

  const get_map_data = async (username, workspace) => {
    let r;
    try {
      r = await axios.get(
        NEW_API_URL + "lessonplan/get-topic-materials/" + topic + "/"
      );

      console.log("topic materials fetch succesful");
      setTopicDetail(r?.data?.topic_detail);
    } catch (error) {
      console.log("Errror fetching topic materials", error);
      try {
        r = await axios.get(
          NEW_API_URL + "lessonplan/get_topic_detail/" + username + "/" + topic + "/"
        );

        console.log("topic details fetch succesful");
        setTopicDetail(r?.data?.topic_detail);
      } catch (error) {
        console.log("Errror fetching topic details", error);
        throw error;
      }
    }
    if (r?.data) {
      let reading_list = r.data.topic_detail.reading_list;
      let notes = r.data.topic_detail?.notes;
      let itools = r.data.topic_detail.itools;
      let timelines = r.data.topic_detail.timelines;
      let news = r.data.topic_detail.news;
      let tests = r.data.topic_detail.tests;
      let pyq_tests = r.data.topic_detail.pyq_tests;

      setCardsProgress({
        readings:
          reading_list.length === 0
            ? 100
            : 100 *
              (reading_list.filter((item) => item.read_status === "Read")
                .length /
                reading_list.length),
        notes:
          notes?.length === 0
            ? 100
            : 100 *
              (notes?.filter((item) => item.read_status === "Read").length /
                notes?.length),
        itools:
          itools.length === 0
            ? 100
            : 100 *
              (itools.filter((item) => item.read_status === "Read").length /
                itools.length),
        timelines:
          timelines.length === 0
            ? 100
            : 100 *
              (timelines.filter((item) => item.read_status === "Read").length /
                timelines.length),
        news:
          news.length === 0
            ? 100
            : 100 *
              (news.filter((item) => item.read_status === "Read").length /
                news.length),
        tests:
          tests.length + pyq_tests.length === 0
            ? 100
            : (100 *
                (tests.filter((item) => item.read_status === "Read").length +
                  pyq_tests.filter((item) => item.read_status === "Read")
                    .length)) /
              (tests.length + pyq_tests.length),
      });
    }

    return r.data;
  };

  console.log(cardProgress, "crdpro");

  const get_lesson_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_lessonplan/" +
        username +
        "/" +
        workspace +
        "/"
    );
    console.log(r);
    setSubjectData(r.data);
    // return r.data
  };

  useEffect(() => {
    get_lesson_map_data(username, workspaceId);
  }, [subject, area, topic]);

  useEffect(() => {
    get_map_data(username, topic);
  }, [subject, area, topic]);

  const items = [
    "Notes",
    "Case Study",
    "Test",
    "Review",
    "Result",
    "Feedback",
    "Notes",
    "Case Study",
    "Test",
    "Review",
    "Result",
    "Feedback",
    "Notes",
    "Case Study",
    "Test",
    "Review",
    "Result",
    "Feedback",
    "Notes",
    "Case Study",
    "Test",
    "Review",
    "Result",
    "Feedback",
  ];

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex mt={"10vh"} alignItems={"center"}>
              <BackArrow />

              <Box style={{ display: "flex", alignItems: "center" }} flex={1}>
                <img
                  src={
                    topicDetail.topic_image ||
                    "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/medieval_history.jfif"
                  }
                  alt={"detail_icon"}
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                />
                <Text mb={0} ml={0} mr={0} fontSize={"26px"}>
                  <strong>{topicDetail.topic_name}</strong>
                </Text>
              </Box>

              <div style={{ width: 50, height: 50, marginRight: 10 }}>
                <CircularProgressbar
                  value={topicDetail.completion_rate}
                  text={`${Math.round(topicDetail.completion_rate, 0)}%`}
                />
                <ButtonPrimary
                  onClick={() => {
                    window.location.href =
                      "/learn/" +
                      subject +
                      "/" +
                      area +
                      "/" +
                      topic +
                      "/concepts";
                    // setNoteType("All Notes");
                    // setNotesDetail(notesDetailOrigial);
                  }}
                  bg={"claydeck.brand.primary"}
                  // mr={"15px"}
                  mt={"15px"}
                  ml={"-40px"}
                  // w={'100%'}
                  color={"white"}
                >
                  Concepts{" "}
                  <Center
                    ml={"10px"}
                    bg={"white"}
                    borderRadius={"36px"}
                    w={"20px"}
                    h={"20px"}
                  >
                    <Image h={"20px"} src={"/arrowright.png"}></Image>
                  </Center>
                </ButtonPrimary>
              </div>

              {topicDetail.topic_priority === "Low" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#5F79D7"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}
              {topicDetail.topic_priority === "Medium" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#F4B400"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}
              {topicDetail.topic_priority === "High" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#FF0E36"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}
            </Flex>
            <Box ml={"60px"} mt={"16px"}>
              <MultiLayerDropdown data={subjectData} text={"Change Topic"} />
            </Box>
            <hr />

            <Flex
              gap={"40px"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Box>
                <P2xl mt={12}>
                  <strong>Learn</strong>
                </P2xl>
                <Wrap spacing={"20px"}>
                  <WrapItem>
                    <TopicCard
                      percent={cardProgress.readings}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/readings"
                      }
                      title={"Reading List"}
                      icon={"/detail_reading.png"}
                      shadow
                    />
                  </WrapItem>
                  <WrapItem>
                    <TopicCard
                      percent={cardProgress.notes}
                      title={"Notes"}
                      icon={"/detail_notes.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/notes"
                      }
                      shadow
                    />
                  </WrapItem>
                </Wrap>
              </Box>

              <Box>
                <P2xl mt={12}>
                  <strong>Master</strong>
                </P2xl>
                <Wrap spacing={"20px"}>
                  <WrapItem>
                    <TopicCard
                      percent={cardProgress.timelines}
                      title={"Maps & Timelines"}
                      icon={"/detail_map.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/timeline"
                      }
                      shadow
                    />
                  </WrapItem>

                  <WrapItem>
                    <TopicCard
                      percent={cardProgress.itools}
                      title={"Case Studies"}
                      icon={"/itools.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/itools"
                      }
                      shadow
                    />
                  </WrapItem>
                </Wrap>
              </Box>
            </Flex>

            <P2xl mt={10}>
              <strong>Apply</strong>
            </P2xl>
            <Wrap spacing={"20px"}>
              <WrapItem>
                <TopicCard
                  to={"/learn/" + subject + "/" + area + "/" + topic + "/news"}
                  title={"News"}
                  percent={cardProgress.news}
                  icon={"/detail_news.png"}
                  shadow
                />
              </WrapItem>
              <WrapItem>
                <TopicCard
                  percent={cardProgress.tests}
                  to={"/learn/" + subject + "/" + area + "/" + topic + "/tests"}
                  title={"Tests"}
                  icon={"/detail_test.png"}
                  shadow
                />
              </WrapItem>
            </Wrap>
          </Box>
          {/* <Box mb={"20px"}>
            <Pmd fontWeight={"300px"} mt={"50px"}>
              To access all the features go to the app
            </Pmd>
            <ButtonPrimary>App Button Here</ButtonPrimary>
          </Box> */}
        </Flex>

        {topicDetail.topic_name && (
          <AskBudAccent
            context={topicDetail.topic_name}
            initText={
              "You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " +
              topicDetail.topic_name +
              " topic of UPSC"
            }
          />
        )}
      </Container>
    </>
  );
};

export default TopicDetail;
