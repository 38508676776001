export const validatePassword = (password) => {
  const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (regexPassword.test(password)) {
    return { isValid: true };
  }

  const errors = [];
  if (!/(?=.*[a-z])/.test(password)) errors.push("one lowercase letter");
  if (!/(?=.*[A-Z])/.test(password)) errors.push("one uppercase letter");
  if (!/(?=.*\d)/.test(password)) errors.push("one number");
  if (!/(?=.*[@$!%*?&])/.test(password)) errors.push("one special character (@$!%*?&)");
  if (password.length < 8) errors.push("minimum 8 characters");

  return {
    isValid: false,
    message: `Password must contain: ${errors.join(", ")}`
  };
};

export const validateEmail = (email) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
};

export const validateName = (name) => {
  const regexName = /^[a-zA-Z ]+$/;
  return regexName.test(name);
};

export const SCREEN_BREAKPOINT = 600; // Minimum screen width for desktop view
