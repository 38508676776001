// Desc: Intro Carousel for the landing page
import { Box, Grid, GridItem, Checkbox, Image, Center, Flex } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import Plg from "../../../Components/Typography/Text/Plg";
import { Link } from "react-router-dom";


const IntroCarousel = () => {

  return (
    <GridItem
      w="100%"
      h="100vh"
      display={{ base: "flex", lg: "flex" }}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Link to={'/'}>
        <Image
          height={"80px"}
          objectFit={"contain"}
          src={"claydeck_logo_full.png"}
          alt={"site-logo"}
        />
      </Link>

      <Carousel autoPlay interval={4000} infiniteLoop showArrows={false} showStatus={false} showThumbs={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorStyle = {
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: isSelected ? 'black' : 'rgba(0, 0, 0, 0.3)', // Black color for selected, lighter color for unselected
            display: 'inline-block',
            margin: '8px',
            cursor: 'pointer',
          };
          return (
            <li key={index} style={indicatorStyle} onClick={onClickHandler} title={label} />
          );
        }}
      >
        <Box height="80vh">
          <Center height="80%">
            <img src={"/intro-s4.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
          </Center>
          <Plg textAlign="center" mt={4}>
            Get daily task
            recommendations and
            <br />
            complete milestones to
            track your progress
          </Plg>
        </Box>
        <Box height="80vh">
          <Center height="80%">
            <img src={"/intro-s1.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
          </Center>
          <Plg textAlign="center" mt={4}>
            An app that adapts
            to your learning <br />style
            and progress
          </Plg>
        </Box>
        <Box height="80vh">
          <Center height="80%">
            <img src={"/intro-s2.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
          </Center>
          <Plg textAlign="center" mt={4}>
            Learn with interactive
            notes and correlate <br />
            topics with visual timelines
          </Plg>
        </Box>
        <Box height="80vh">
          <Center height="80%">
            <img src={"/intro-s3.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
          </Center>
          <Plg textAlign="center" mt={4}>
            Stay aware of
            most important current <br />affairs
            with Claydeck’s AI priority
          </Plg>
        </Box>
      </Carousel>
    </GridItem>
  );
};

export default IntroCarousel;
