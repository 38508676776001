import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Container,
  Flex,
  Select,
  Center,
  Box,
  Text,
  useClipboard,
  HStack,
  Image,
} from "@chakra-ui/react";
import Pmd from "../Typography/Text/Pmd";
import ButtonPrimary from "../Button/ButtonPrimary";
import InputOutline from "../Input/InputOutline";
import InputTextAreaOutline from "../Input/InputTextAreaOutline";
import FileUpload from "../FileUpload";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import ButtonSecondary from "../Button/ButtonSecondary";
import Pxl from "../Typography/Text/Pxl";
import Plg from "../Typography/Text/Plg";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { NEW_API_URL } from "../../redux/types";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import Psm from "../Typography/Text/Psm";
import HeadCard from "../HeadCard";

function CustomStyledModal(props) {
  const [relatedItems, setRelatedItems] = useState(
    props.initData?.attachments?.related_items ?? []
  );
  const [title, setTitle] = useState(props.initData?.title ?? "");
  const [link, setLink] = useState(
    props.initData?.attachments?.links?.join(",") ?? ""
  );
  const [description, setDescription] = useState(
    props.initData?.description ?? ""
  );
  const [file, setFile] = useState([]);
  const [imageUrls, setImageUrls] = useState(
    props.initData?.attachments?.images ?? []
  );
  const [preview, setPreview] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsFiltered, setSubscriptionsFiltered] = useState([]);
  const [subscriptionFormatSwitch, setSubscriptionFormatSwitch] =
    useState("one-time");
  const toast = useToast();
  console.log(props.topicDetail, "files");
  const username = useSelector((state) => state.user.username);
  const { hasCopied, onCopy } = useClipboard(props.link);

  useEffect(() => {
    if (props.initData?.attachments?.images) {
      setFile(
        props.initData?.attachments?.images.map((i) => {
          return {
            file: null,
            url: i,
          };
        })
      );
    }
  }, [props.initData?.attachments?.images]);

  const activatePlan = async (username, plan_id) => {
    try {
      // setIsLoading(true);
      const res = await axios.post(
        `${NEW_API_URL}community/api/activate-plan/`,
        {
          username: username,
          plan_id: plan_id,
        }
      );
      console.log(res.data, "subs");
      return res.data;
    } catch (error) {
      // setIsLoading(false);
      console.error("home data fetch failed", error);
    }
  };

  useEffect(() => {
    if (props.force_rel || props.force_topic) {
      setRelatedItems([
        {
          id: props.force_id,
          rel: props.force_rel,
          chap: props.force_topic,
        },
      ]);
    }
  }, [props.force_rel, props.force_topic]);
  console.log(props.initData, "initdata");

  useEffect(() => {
    if (props.add_note && props.isOpen === true && props.selectedText) {
      setDescription(props.selectedText);
    }
  }, [props.selectedText]);

  const get_type_of_note = (note) => {
    if (note === "note") return "Concept";
    if (note === "reading") return "Reading";
    if (note === "news") return "News";
    if (note === "timeline") return "TimelineEvent";
  };

  useEffect(() => {
    if (props.subscription_new) {
      axios
        .get(NEW_API_URL + "community/plans/" + username)
        .then((response) => {
          setSubscriptions(response.data);
          setSubscriptionsFiltered(response.data.filter((i) => !i.renewal));
        });
    }
  }, [props.selectedText]);

  if (props.subscription_new) {
    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={"container.md"} borderRadius={"12px"}>
          <ModalHeader
            color={"white"}
            borderRadius={"12px"}
            bg={"#003D5F"}
            textAlign={"center"}
          >
            Congratulations
            <br />
            Your account is created!
            <br />
            Choose a plan below to continue
            <br />
            <br />
            <Pmd color={"white"}>
              With all of Claydeck’s plans, get unlimited access to:
            </Pmd>
            <Center>
              <Box color={"white"}>
                <Flex>
                  <Image src={"/read_tick.png"} h={"20px"} w={"20px"}></Image>
                  <Pmd color={"white"} ml={"10px"}>
                    1000+ interactive activities to master all UPSC topics
                  </Pmd>
                </Flex>
                <Flex>
                  <Image src={"/read_tick.png"} h={"20px"} w={"20px"}></Image>
                  <Pmd color={"white"} ml={"10px"}>
                    100+ timelines and visualizations to understand connections
                    between topics
                  </Pmd>
                </Flex>
                <Flex>
                  <Image src={"/read_tick.png"} h={"20px"} w={"20px"}></Image>
                  <Pmd color={"white"} ml={"10px"}>
                    Priority of news topics to cover for UPSC updated daily
                  </Pmd>
                </Flex>
                <Flex>
                  <Image src={"/read_tick.png"} h={"20px"} w={"20px"}></Image>
                  <Pmd color={"white"} ml={"10px"}>
                    Get instant doubt clarification at any time
                  </Pmd>
                </Flex>
              </Box>
            </Center>
          </ModalHeader>
          {/*<ModalCloseButton />*/}
          <ModalBody padding={"24px"}>
            <Flex w={"100%"} justifyContent={"flex-end"}>
              <Flex bg={"#ADB3B7"} borderRadius={"12px"} w={"147px"}>
                <Psm
                  m={0}
                  color={"white"}
                  padding={"10px"}
                  paddingBottom={"3px"}
                  paddingTop={"3px"}
                  borderRadius={"12px"}
                  bg={
                    subscriptionFormatSwitch === "monthly"
                      ? "#0962A2"
                      : "transparent"
                  }
                  cursor={"pointer"}
                  onClick={() => {
                    setSubscriptionFormatSwitch("monthly");
                    console.log(subscriptions);
                    setSubscriptionsFiltered(
                      subscriptions.filter((i) => i.renewal)
                    );
                  }}
                >
                  Monthly
                </Psm>
                <Psm
                  m={0}
                  color={"white"}
                  padding={"10px"}
                  paddingBottom={"3px"}
                  paddingTop={"3px"}
                  borderRadius={"12px"}
                  bg={
                    subscriptionFormatSwitch === "one-time"
                      ? "#0962A2"
                      : "transparent"
                  }
                  cursor={"pointer"}
                  onClick={() => {
                    setSubscriptionFormatSwitch("one-time");
                    setSubscriptionsFiltered(
                      subscriptions.filter((i) => !i.renewal)
                    );
                  }}
                >
                  One-time
                </Psm>
              </Flex>
            </Flex>
            <Flex flexWrap={"wrap"}>
              {subscriptionsFiltered.map((item, index) => (
                <HeadCard
                  cursor={"pointer"}
                  onClick={() => {
                    activatePlan(props.username, item.plan_id).then((r) => {
                      alert("Plan activated");
                      window.location.reload();
                    });
                  }}
                  titlecolor={"#0962A2"}
                  title={item.title}
                  style={{ boxShadow: "none", border: "1px solid #0962A2" }}
                  w={"200px"}
                >
                  <Plg ml={"20px"}>
                    <strong>{item.price === 0 ? "Free" : item.price}</strong>
                    {item.renewal ? (
                      <span style={{ color: "gray" }}>/month</span>
                    ) : (
                      <></>
                    )}
                  </Plg>

                  <Pmd ml={"20px"}>
                    Pay : <strong>₹{item.price}</strong>
                  </Pmd>
                </HeadCard>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (props.share_link) {
    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"12px"}
          borderTop={"4px solid #0962A2"}
          maxWidth={"container.md"}
        >
          <ModalHeader textAlign={"center"}>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={"24px"}>
            <Box mb={2}>
              <Text textAlign={"center"}>{props.link}</Text>
            </Box>
            <Center>
              <ButtonPrimary
                onClick={onCopy}
                bg={"transparent"}
                color={"#0962A2"}
                border={"1px solid #0962A2"}
                mt={"22px"}
                mb={"12px"}
                minW={"60%"}
              >
                {hasCopied ? "Copied!" : "Copy Link"}
              </ButtonPrimary>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  if (props.conceptReader) {
    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"12px"}
          borderTop={"4px solid #0962A2"}
          maxWidth={"container.md"}
        >
          <ModalCloseButton />
          <ModalBody padding={"24px"}>
            <Center>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Image src={"/ok_mark.png"} h={"80px"} />
                <Plg mt={8}>
                  <strong>All done! You have completed:</strong>
                </Plg>
                <br />
                <Plg m={0}>
                  <strong>{props.conceptReader}</strong>
                </Plg>
                <ButtonPrimary
                  onClick={() => {
                    window.history.back();
                  }}
                  bg={"transparent"}
                  color={"#0962A2"}
                  border={"1px solid #0962A2"}
                  mt={"22px"}
                  mb={"12px"}
                  minW={"60%"}
                >
                  Close
                </ButtonPrimary>
              </Flex>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (props.subscription) {
    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"12px"}
          borderTop={"4px solid #0962A2"}
          maxWidth={"container.lg"}
        >
          <ModalHeader textAlign={"center"}>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Pmd>
              We were not able to find any active subscription on your account
              linked with {props.username}. Please go to our mobile app and
              activate your subscription to continue accessing Claydeck
            </Pmd>
          </ModalBody>
          <ModalFooter textAlign={"center"} justifyContent={"center"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (props.hint) {
    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"12px"}
          borderTop={"4px solid #0962A2"}
          maxWidth={"container.lg"}
        >
          <ModalHeader textAlign={"center"}>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/*<Container maxW={'container.md'}>*/}
            <Center w={"100%"}>
              <Center
                bgColor={"white"}
                w={"100%"}
                flexDirection={"column"}
                mt={"30px"}
              >
                {props.quizData?.questions[props.currentQuestion].hint_concepts
                  .length > 0 && (
                  <Plg>
                    <strong>Related Concepts:</strong>
                  </Plg>
                )}
                {props.quizData?.questions[
                  props.currentQuestion
                ].hint_concepts.map((item, idx) => (
                  <Plg color="#2A6291" key={idx}>
                    {item.name}
                  </Plg>
                ))}
                {props.quizData?.questions[props.currentQuestion].hint_news
                  .length > 0 && (
                  <Plg mt={"20px"}>
                    <strong>Related News:</strong>
                  </Plg>
                )}
                {props.quizData?.questions[props.currentQuestion].hint_news.map(
                  (item, idx) => (
                    <Plg color="#2A6291" key={idx}>
                      {item.name}
                    </Plg>
                  )
                )}

                <Plg
                  color="#2A6291"
                  mt={"20px"}
                  onClick={() => {
                    if (props.onMoreHints) {
                      props.onMoreHints();
                    }
                  }}
                >
                  <u>Get more hints</u>
                </Plg>
                {/*<Plg></Plg>*/}
              </Center>
            </Center>
            {/*</Container>*/}
          </ModalBody>

          <ModalFooter textAlign={"center"} justifyContent={"center"}>
            <ButtonPrimary colorScheme="blue" mr={3} onClick={props.onClose}>
              Close Hint
            </ButtonPrimary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (props.add_note && preview) {
    return (
      <>
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay />
          <ModalContent
            borderRadius={"12px"}
            borderTop={"4px solid #0962A2"}
            maxWidth={"container.lg"}
          >
            <ModalHeader textAlign={"center"}>
              {title}
              <hr />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container maxW={"container.md"}>
                <Pmd>{description}</Pmd>
                {Array.from(file).map((item, idx) => (
                  <img
                    alt="preview image"
                    src={URL.createObjectURL(item)}
                    key={idx}
                  />
                ))}
                <Pmd>Related Items</Pmd>
                <Flex>
                  <Pmd>Links</Pmd>
                  <Pmd>{link}</Pmd>
                </Flex>

                {relatedItems.map((item, index) => {
                  // if(item.edit){
                  return (
                    <Flex>
                      {!item.edit && (
                        <>
                          <Pmd>
                            {item.rel} : {item.chap}
                          </Pmd>
                        </>
                      )}
                    </Flex>
                  );
                  // }
                })}
              </Container>
            </ModalBody>

            <ModalFooter textAlign={"center"} justifyContent={"center"}>
              <ButtonSecondary
                outline
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  props.onClose();
                  setPreview(false);
                }}
              >
                Cancel
              </ButtonSecondary>
              <ButtonPrimary
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  let payload = new FormData();

                  payload.append("note_type", "user");
                  payload.append("title", title);
                  payload.append("description", description);
                  payload.append("user_id", username);
                  payload.append("category", "note");
                  payload.append("topic_id", props.topic_id?.toString());
                  payload.append("topic", props.topic_name || "");
                  payload.append("area", props.area || "");
                  payload.append("subject", props.subject || "");
                  payload.append("links", JSON.stringify(link.split(",")));

                  const relatedItemsObj = relatedItems.map((item) => {
                    if (get_type_of_note(item?.rel) === "News") {
                      return {
                        date: props.force_date,
                        title: item.chap,
                        type: get_type_of_note(item.rel),
                        id: props?.force_id,
                      };
                    } else {
                      return {
                        id: item.id,
                        title: item.chap,
                        type: get_type_of_note(item.rel),
                        topic_id: props.topic_id,
                      };
                    }
                  });

                  if (props.add_type_topic) {
                    relatedItemsObj.push({
                      id: props.topic_id,
                      title: props.topicDetail.topic_name,
                      type: "Topic",
                      topic_id: props.topic_id,
                    });
                  }

                  console.log("related object --->", relatedItemsObj);

                  payload.append(
                    "related_items",
                    JSON.stringify(relatedItemsObj)
                  );

                  for (let i = 0; i < file.length; i++) {
                    payload.append(
                      `attachment`,
                      file[i],
                      `image_${i}.${file[i].name.split(".").pop()}`
                    );
                  }

                  axios
                    .post(NEW_API_URL + "notes/create_note/", payload, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((r) => {
                      toast({
                        title: "Note created.",
                        description: props.toast,
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      setPreview(false);
                      setRelatedItems([]);
                      setTitle("");
                      setLink("");
                      setDescription("");
                      if (props.successNoteAdd) props.successNoteAdd();
                    })
                    .catch((error) => {
                      toast({
                        title: "Error creating note.",
                        description: "Failed to create note. Please try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                      console.error("Upload error:", error);
                    });

                  props.onClose();
                }}
              >
                Create
              </ButtonPrimary>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  } else if (props.add_note) {
    return (
      <>
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay />
          <ModalContent
            borderRadius={"12px"}
            borderTop={"4px solid #0962A2"}
            maxWidth={"container.lg"}
          >
            <ModalHeader textAlign={"center"}>
              {props.title}
              <hr />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container maxW={"container.md"}>
                <Pmd margin={0}>Title</Pmd>
                <InputOutline
                  borderRadius={"12px"}
                  background={"#EBEBEB"}
                  variant={"filled"}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  borderWidth={"2px"}
                />
                <Pmd margin={0}>Description</Pmd>
                <InputTextAreaOutline
                  borderRadius={"12px"}
                  background={"#EBEBEB"}
                  variant={"filled"}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  borderWidth={"0px"}
                />
                <Pmd margin={0}>Link</Pmd>
                <InputOutline
                  borderRadius={"12px"}
                  background={"#EBEBEB"}
                  variant={"filled"}
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                  borderWidth={"2px"}
                />
                <FileUpload
                  imageUrls={imageUrls}
                  setImageData={setFile}
                  style={{ width: "50%" }}
                />
                <Flex mt={"20px"} alignItems={"center"}>
                  <Pmd margin={0}>Related Items</Pmd>
                  {!props.force_rel && (
                    <AddIcon
                      cursor={"pointer"}
                      color={"#0962A2"}
                      ml={"20px"}
                      onClick={() => {
                        setRelatedItems(relatedItems.concat({ edit: true }));
                      }}
                    />
                  )}
                </Flex>

                {relatedItems.map((item, index) => {
                  // if(item.edit){
                  return (
                    <Flex mt={"20px"} alignItems={"center"}>
                      {item.edit && (
                        <>
                          <Select
                            disabled={props.force_rel}
                            value={item.rel}
                            onChange={(e) => {
                              let cpy = JSON.parse(
                                JSON.stringify(relatedItems)
                              );
                              cpy[index].rel = e.target.value;
                              setRelatedItems(cpy);
                            }}
                            key={index}
                            placeholder="Select"
                            borderRadius={"8px"}
                            w={"30%"}
                            border={"0.5px solid #000000"}
                          >
                            <option value="note">Note</option>
                            <option value="reading">Reading</option>
                            <option value="news">News</option>
                          </Select>
                          <Select
                            disabled={props.force_topic}
                            placeholder="Select"
                            value={item.raw}
                            onChange={(e) => {
                              let cpy = JSON.parse(
                                JSON.stringify(relatedItems)
                              );
                              cpy[index].chap = JSON.parse(
                                e.target.value
                              ).title;
                              cpy[index].id = JSON.parse(e.target.value).id;
                              cpy[index].raw = e.target.value;
                              setRelatedItems(cpy);
                              console.log(typeof e.target.value);
                            }}
                            key={index}
                            borderRadius={"8px"}
                            ml={"15px"}
                            w={"30%"}
                            border={"0.5px solid #000000"}
                          >
                            {relatedItems[index].rel === "note" &&
                              props.topicDetail &&
                              props.topicDetail.concepts.map((item, index) => (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.title}
                                </option>
                              ))}

                            {relatedItems[index].rel === "reading" &&
                              props.topicDetail &&
                              props.topicDetail.reading_list.map(
                                (item, index) => (
                                  <option
                                    value={JSON.stringify(item)}
                                    key={index}
                                  >
                                    {item.title}
                                  </option>
                                )
                              )}

                            {relatedItems[index].rel === "news" &&
                              props.topicDetail &&
                              props.topicDetail.news.map((item, index) => (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.title}
                                </option>
                              ))}
                            {/*<optgroup label="British Conquest">*/}
                            {/*<option value='chap1'>chap1</option>*/}
                            {/*</optgroup>*/}
                            {/*<optgroup label="All">*/}
                            {/*    <option value='reading'>chap2-aa</option>*/}
                            {/*    <option value='news'>chap3-bb</option>*/}
                            {/*</optgroup>*/}
                          </Select>

                          <CheckIcon
                            color={
                              item.hasOwnProperty("rel") &&
                              item.hasOwnProperty("chap") &&
                              item.rel.length > 0 &&
                              item.chap.length > 0
                                ? "#0962A2"
                                : "gray.300"
                            }
                            boxSize={"30px"}
                            ml={"10px"}
                            cursor={"pointer"}
                            onClick={() => {
                              if (
                                item.hasOwnProperty("rel") &&
                                item.hasOwnProperty("chap") &&
                                item.rel.length > 0 &&
                                item.chap.length > 0
                              ) {
                                let cpy = JSON.parse(
                                  JSON.stringify(relatedItems)
                                );
                                cpy[index].edit = false;
                                setRelatedItems(cpy);
                              }
                            }}
                          />
                        </>
                      )}
                      {!item.edit && (
                        <>
                          <Pmd>
                            {item.rel} : {item.chap}
                          </Pmd>
                        </>
                      )}

                      {!props.force_rel && (
                        <CloseIcon
                          color={"#0962A2"}
                          boxSize={"24px"}
                          ml={"10px"}
                          cursor={"pointer"}
                          onClick={() => {
                            let cpy = JSON.parse(JSON.stringify(relatedItems));
                            cpy.splice(index, 1);
                            console.log(cpy);
                            setRelatedItems(cpy);
                          }}
                        />
                      )}
                    </Flex>
                  );
                  // }
                })}
              </Container>
            </ModalBody>

            <ModalFooter textAlign={"center"} justifyContent={"center"}>
              <ButtonSecondary
                outline
                colorScheme="blue"
                mr={3}
                onClick={props.onClose}
                width={"30%"}
              >
                Cancel
              </ButtonSecondary>
              <ButtonPrimary
                colorScheme="blue"
                mr={3}
                width={"30%"}
                onClick={() => {
                  let payload = new FormData();

                  payload.append("note_type", "user");
                  payload.append("title", title);
                  payload.append("description", description);
                  payload.append("user_id", username);
                  payload.append("category", "note");
                  payload.append("topic_id", props.topic_id?.toString());
                  payload.append("topic", props.topic_name || "");
                  payload.append("area", props.area || "");
                  payload.append("subject", props.subject || "");
                  payload.append("links", JSON.stringify(link.split(",")));
                  if (props.initData && props.initData.id) {
                    payload.append("id", props.initData.id);
                  }

                  const relatedItemsObj = relatedItems.map((item) => {
                    console.log("related item", item);
                    if (get_type_of_note(item?.rel) === "News") {
                      return {
                        date: props.force_date,
                        title: item.chap,
                        type: get_type_of_note(item.rel),
                        id: props?.force_id,
                      };
                    } else {
                      return {
                        id: item.id || props.force_id,
                        title: item.chap,
                        type: get_type_of_note(item.rel),
                        topic_id: props.topic_id,
                      };
                    }
                  });
                  console.log(relatedItemsObj, "relitemopbo");

                  if (props.add_type_topic) {
                    relatedItemsObj.push({
                      id: props.topic_id,
                      title: props.topicDetail.topic_name,
                      type: "Topic",
                      topic_id: props.topic_id,
                    });
                  }

                  console.log(
                    "related object add note only --->",
                    relatedItemsObj
                  );

                  if (
                    props.initData &&
                    props.initData.attachments.related_items
                  ) {
                    payload.append(
                      "related_items",
                      JSON.stringify(props.initData.attachments.related_items)
                    );
                  } else {
                    payload.append(
                      "related_items",
                      JSON.stringify(relatedItemsObj)
                    );
                  }

                  for (let i = 0; i < file.length; i++) {
                    if (file[i].file)
                      payload.append(
                        `attachment`,
                        file[i],
                        `image_${i}.${file[i].name.split(".").pop()}`
                      );
                  }

                  axios
                    .post(NEW_API_URL + "notes/create_note/", payload, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((r) => {
                      toast({
                        title: "Note created.",
                        description: props.toast,
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      setPreview(false);
                      setRelatedItems([]);
                      setTitle("");
                      setLink("");
                      setDescription("");
                      if (props.successNoteAdd) props.successNoteAdd();
                    })
                    .catch((error) => {
                      toast({
                        title: "Error creating note.",
                        description: "Failed to create note. Please try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                      console.error("Upload error:", error);
                    });

                  props.onClose();
                }}
              >
                Create
              </ButtonPrimary>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  if (props.quick_explain) {
    return (
      <>
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay />
          <ModalContent
            borderRadius={"12px"}
            borderTop={"4px solid #0962A2"}
            maxWidth={"container.lg"}
          >
            <ModalHeader textAlign={"center"}>{props.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container maxW={"container.md"}>
                <table>
                  <tbody>
                    <tr>
                      {/* <td style={{ verticalAlign: "top" }}>
                        <Pmd>
                          <strong>Selected Text:</strong>
                        </Pmd>
                      </td> */}
                      <td>
                        <HStack>
                          <Pmd>
                            <strong>Selected Text:</strong>
                          </Pmd>
                          <Pmd>
                            <strong>{props.selectedText}</strong>
                          </Pmd>
                        </HStack>
                      </td>
                    </tr>
                    {props.isLoading ? (
                      <tr>
                        {/* <td style={{ verticalAlign: "top" }}></td> */}
                        <td>Loading...</td>
                      </tr>
                    ) : (
                      <tr>
                        {/* <td style={{ verticalAlign: "top" }}>Explanation:</td> */}
                        <td>
                          <Markdown>{props.quick_exp}</Markdown>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Container>
            </ModalBody>

            <ModalFooter textAlign={"center"} justifyContent={"center"}>
              <ButtonPrimary
                colorScheme="blue"
                mr={3}
                onClick={props.onClose}
                width={"50%"}
              >
                Close
              </ButtonPrimary>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  if (props.review_quiz) {
    return (
      <>
        <Modal
          isOpen={props.isOpen}
          onClose={props.onClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent
            borderRadius={"12px"}
            borderTop={"4px solid #0962A2"}
            maxWidth={"container.sm"}
          >
            <ModalHeader textAlign={"center"}>{props.title}</ModalHeader>

            <ModalBody>
              <Container maxW={"container.md"}>
                <Pxl>
                  Do you want to re-take this test or review your previous
                  answers?
                </Pxl>
              </Container>
            </ModalBody>

            <ModalFooter
              textAlign={"center"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <ButtonPrimary
                colorScheme="white"
                mr={3}
                onClick={props.onRetakeClick}
              >
                Retake
              </ButtonPrimary>
              <ButtonSecondary
                colorScheme="blue"
                mr={3}
                onClick={props.onReviewClick}
              >
                Review
              </ButtonSecondary>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.description}</ModalBody>

          <ModalFooter>
            <ButtonPrimary colorScheme="blue" mr={3} onClick={props.onClose}>
              Close
            </ButtonPrimary>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CustomStyledModal;
