import React from 'react';

import {Position, ReactFlow} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }]

const styles = {
    // background: 'red',
    // width: '100%',
    // height: 300,
};

const mindMapData = {
    title: "Programming Fundamentals",
    color: "#FFE4E1",
    description: "Core programming concepts",
    children: [
        {
            title: "Frontend Development",
            color: "#FFE4C4",
            description: "Building interactive user interfaces",
            children: [
                {
                    title: "fd1:HTML",
                    children: [
                        { title: "html1" },
                        { title: "html2" },
                        { title: "html3" },
                    ],
                },
                {
                    title: "fd2:CSS",
                    description: "Styling web pages",
                    children: [
                        { title: "css1" },
                        { title: "css2" },
                        { title: "css3" },
                    ],
                },
                {
                    title: "fd3:JavaScript",
                    children: [
                        { title: "js1" },
                        { title: "js2:ES6+ Features" },
                        { title: "js3:Async Programming" },
                    ],
                },
            ],
        },
        {
            title: "Backend Development",
            color: "#E0FFF0",
            description: "Building server-side applications",
            children: [
                {
                    title: "Databases",
                    children: [
                        { title: "SQL" },
                        { title: "NoSQL" },
                        { title: "ORM" },
                    ],
                },
                {
                    title: "APIs",
                    children: [
                        { title: "REST" },
                        { title: "GraphQL" },
                        { title: "WebSockets" },
                    ],
                },
                {
                    title: "Server",
                    children: [
                        { title: "Node.js" },
                        { title: "Express" },
                        { title: "Authentication" },
                    ],
                },
            ],
        },
        {
            title: "DevOps",
            color: "#E6E6FA",
            description: "Automating software development and deployment",
            children: [
                {
                    title: "Version Control",
                    children: [
                        { title: "Git Basics" },
                        { title: "Branching Strategy" },
                        { title: "CI/CD" },
                    ],
                },
                {
                    title: "Deployment",
                    children: [
                        { title: "Docker" },
                        { title: "Kubernetes" },
                        { title: "Cloud Services" },
                    ],
                },
                {
                    title: "Monitoring",
                    children: [
                        { title: "Logging" },
                        { title: "Performance" },
                        { title: "Security" },
                    ],
                },
            ],
        },
        {
            title: "Mobile Development",
            color: "#FFF0F5",
            description: "Building native and cross-platform mobile apps",
            children: [
                {
                    title: "Cross Platform",
                    children: [
                        { title: "React Native" },
                        { title: "Flutter" },
                        { title: "Ionic" },
                    ],
                },
                {
                    title: "Native iOS",
                    children: [
                        { title: "Swift" },
                        { title: "UIKit" },
                        { title: "SwiftUI" },
                    ],
                },
                {
                    title: "Native Android",
                    children: [
                        { title: "Kotlin" },
                        { title: "Jetpack Compose" },
                        { title: "Android SDK" },
                    ],
                },
            ],
        },
    ],
};


function FlowTest(props) {
    function convertToReactFlow(data, startX = 0, startY = 0, xSpacing = 170, ySpacing = 50) {
        let nodes = [];
        let edges = [];
        let idCounter = 1;

        function calculateSubtreeHeight(node) {
            if (!node.children || node.children.length === 0) {
                return 1;
            }
            return node.children.reduce((acc, child) => acc + calculateSubtreeHeight(child), 0);
        }

        function traverse(node, parentId = null, x = startX, y = startY) {
            const currentId = idCounter.toString();
            idCounter++;
            nodes.push({
                id: currentId,
                position: {x, y},
                data: {label: node.title, description: node.description || ""},
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                style: { backgroundColor: node.color?node.color:'gold', color: 'black' }
            });
            if (parentId) {
                edges.push({
                    id: `e${parentId}-${currentId}`,
                    source: parentId,
                    target: currentId,
                });
            }
            if (node.children && node.children.length > 0) {
                const totalHeight = calculateSubtreeHeight(node) * ySpacing;
                let currentY = y - totalHeight / 2;

                node.children.forEach((child) => {
                    const subtreeHeight = calculateSubtreeHeight(child) * ySpacing;
                    traverse(child, currentId, x + xSpacing, currentY + subtreeHeight / 2);
                    currentY += subtreeHeight;
                });
            }
        }

        traverse(data);
        return {nodes, edges};
    }

    const {nodes, edges} = convertToReactFlow(mindMapData);
    return (
        <div style={{width: '100vw', height: '100vh'}}>
            <ReactFlow style={styles} nodes={nodes} edges={edges}/>
        </div>
    );
}

export default FlowTest;
