import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import Markdown from "react-markdown";
import TimelineViz from "../../Components/TimelineViz";
import BackArrow from "../../Components/backArrow";

const markdown_str =
  "---\n" +
  "__Advertisement :)__\n" +
  "\n" +
  "- __[pica](https://nodeca.github.io/pica/demo/)__ - high quality and fast image\n" +
  "  resize in browser.\n" +
  "- __[babelfish](https://github.com/nodeca/babelfish/)__ - developer friendly\n" +
  "  i18n with plurals support and easy syntax.\n" +
  "\n" +
  "You will like those projects!\n" +
  "\n" +
  "---\n" +
  "\n" +
  "# h1 Heading 8-)\n" +
  "## h2 Heading\n" +
  "### h3 Heading\n" +
  "#### h4 Heading\n" +
  "##### h5 Heading\n" +
  "###### h6 Heading\n" +
  "\n" +
  "\n" +
  "## Horizontal Rules\n" +
  "\n" +
  "___\n" +
  "\n" +
  "---\n" +
  "\n" +
  "***\n" +
  "\n" +
  "\n" +
  "## Typographic replacements\n" +
  "\n" +
  "Enable typographer option to see result.\n" +
  "\n" +
  "(c) (C) (r) (R) (tm) (TM) (p) (P) +-\n" +
  "\n" +
  "test.. test... test..... test?..... test!....\n" +
  "\n" +
  "!!!!!! ???? ,,  -- ---\n" +
  "\n" +
  "\"Smartypants, double quotes\" and 'single quotes'\n" +
  "\n" +
  "\n" +
  "## Emphasis\n" +
  "\n" +
  "**This is bold text**\n" +
  "\n" +
  "__This is bold text__\n" +
  "\n" +
  "*This is italic text*\n" +
  "\n" +
  "_This is italic text_\n" +
  "\n" +
  "~~Strikethrough~~\n" +
  "\n" +
  "\n" +
  "## Blockquotes\n" +
  "\n" +
  "\n" +
  "> Blockquotes can also be nested...\n" +
  ">> ...by using additional greater-than signs right next to each other...\n" +
  "> > > ...or with spaces between arrows.\n" +
  "\n" +
  "\n" +
  "## Lists\n" +
  "\n" +
  "Unordered\n" +
  "\n" +
  "+ Create a list by starting a line with `+`, `-`, or `*`\n" +
  "+ Sub-lists are made by indenting 2 spaces:\n" +
  "  - Marker character change forces new list start:\n" +
  "    * Ac tristique libero volutpat at\n" +
  "    + Facilisis in pretium nisl aliquet\n" +
  "    - Nulla volutpat aliquam velit\n" +
  "+ Very easy!\n" +
  "\n" +
  "Ordered\n" +
  "\n" +
  "1. Lorem ipsum dolor sit amet\n" +
  "2. Consectetur adipiscing elit\n" +
  "3. Integer molestie lorem at massa\n" +
  "\n" +
  "\n" +
  "1. You can use sequential numbers...\n" +
  "1. ...or keep all the numbers as `1.`\n" +
  "\n" +
  "Start numbering with offset:\n" +
  "\n" +
  "57. foo\n" +
  "1. bar\n" +
  "\n" +
  "\n" +
  "## Code\n" +
  "\n" +
  "Inline `code`\n" +
  "\n" +
  "Indented code\n" +
  "\n" +
  "    // Some comments\n" +
  "    line 1 of code\n" +
  "    line 2 of code\n" +
  "    line 3 of code\n" +
  "\n" +
  "\n" +
  'Block code "fences"\n' +
  "\n" +
  "```\n" +
  "Sample text here...\n" +
  "```\n" +
  "\n" +
  "Syntax highlighting\n" +
  "\n" +
  "``` js\n" +
  "var foo = function (bar) {\n" +
  "  return bar++;\n" +
  "};\n" +
  "\n" +
  "console.log(foo(5));\n" +
  "```\n" +
  "\n" +
  "## Tables\n" +
  "\n" +
  "| Option | Description |\n" +
  "| ------ | ----------- |\n" +
  "| data   | path to data files to supply the data that will be passed into templates. |\n" +
  "| engine | engine to be used for processing templates. Handlebars is the default. |\n" +
  "| ext    | extension to be used for dest files. |\n" +
  "\n" +
  "Right aligned columns\n" +
  "\n" +
  "| Option | Description |\n" +
  "| ------:| -----------:|\n" +
  "| data   | path to data files to supply the data that will be passed into templates. |\n" +
  "| engine | engine to be used for processing templates. Handlebars is the default. |\n" +
  "| ext    | extension to be used for dest files. |\n" +
  "\n" +
  "\n" +
  "## Links\n" +
  "\n" +
  "[link text](http://dev.nodeca.com)\n" +
  "\n" +
  '[link with title](http://nodeca.github.io/pica/demo/ "title text!")\n' +
  "\n" +
  "Autoconverted link https://github.com/nodeca/pica (enable linkify to see)\n" +
  "\n" +
  "\n" +
  "## Images\n" +
  "\n" +
  "![Minion](https://octodex.github.com/images/minion.png)\n" +
  '![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat")\n' +
  "\n" +
  "Like links, Images also have a footnote style syntax\n" +
  "\n" +
  "![Alt text][id]\n" +
  "\n" +
  "With a reference later in the document defining the URL location:\n" +
  "\n" +
  '[id]: https://octodex.github.com/images/dojocat.jpg  "The Dojocat"\n' +
  "\n" +
  "\n" +
  "## Plugins\n" +
  "\n" +
  "The killer feature of `markdown-it` is very effective support of\n" +
  "[syntax plugins](https://www.npmjs.org/browse/keyword/markdown-it-plugin).\n" +
  "\n" +
  "\n" +
  "### [Emojies](https://github.com/markdown-it/markdown-it-emoji)\n" +
  "\n" +
  "> Classic markup: :wink: :cry: :laughing: :yum:\n" +
  ">\n" +
  "> Shortcuts (emoticons): :-) :-( 8-) ;)\n" +
  "\n" +
  "see [how to change output](https://github.com/markdown-it/markdown-it-emoji#change-output) with twemoji.\n" +
  "\n" +
  "\n" +
  "### [Subscript](https://github.com/markdown-it/markdown-it-sub) / [Superscript](https://github.com/markdown-it/markdown-it-sup)\n" +
  "\n" +
  "- 19^th^\n" +
  "- H~2~O\n" +
  "\n" +
  "\n" +
  "### [\\<ins>](https://github.com/markdown-it/markdown-it-ins)\n" +
  "\n" +
  "++Inserted text++\n" +
  "\n" +
  "\n" +
  "### [\\<mark>](https://github.com/markdown-it/markdown-it-mark)\n" +
  "\n" +
  "==Marked text==\n" +
  "\n" +
  "\n" +
  "### [Footnotes](https://github.com/markdown-it/markdown-it-footnote)\n" +
  "\n" +
  "Footnote 1 link[^first].\n" +
  "\n" +
  "Footnote 2 link[^second].\n" +
  "\n" +
  "Inline footnote^[Text of inline footnote] definition.\n" +
  "\n" +
  "Duplicated footnote reference[^second].\n" +
  "\n" +
  "[^first]: Footnote **can have markup**\n" +
  "\n" +
  "    and multiple paragraphs.\n" +
  "\n" +
  "[^second]: Footnote text.\n" +
  "\n" +
  "\n" +
  "### [Definition lists](https://github.com/markdown-it/markdown-it-deflist)\n" +
  "\n" +
  "Term 1\n" +
  "\n" +
  ":   Definition 1\n" +
  "with lazy continuation.\n" +
  "\n" +
  "Term 2 with *inline markup*\n" +
  "\n" +
  ":   Definition 2\n" +
  "\n" +
  "        { some code, part of Definition 2 }\n" +
  "\n" +
  "    Third paragraph of definition 2.\n" +
  "\n" +
  "_Compact style:_\n" +
  "\n" +
  "Term 1\n" +
  "  ~ Definition 1\n" +
  "\n" +
  "Term 2\n" +
  "  ~ Definition 2a\n" +
  "  ~ Definition 2b\n" +
  "\n" +
  "\n" +
  "### [Abbreviations](https://github.com/markdown-it/markdown-it-abbr)\n" +
  "\n" +
  "This is HTML abbreviation example.\n" +
  "\n" +
  'It converts "HTML", but keep intact partial entries like "xxxHTMLyyy" and so on.\n' +
  "\n" +
  "*[HTML]: Hyper Text Markup Language\n" +
  "\n" +
  "### [Custom containers](https://github.com/markdown-it/markdown-it-container)\n" +
  "\n" +
  "::: warning\n" +
  "*here be dragons*\n" +
  ":::\n";

const TimelineReading = (props) => {
  const { topic, subject, area, noteid } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const newUser = useSelector((state) => state.user.newUser);
  const username = useSelector((state) => state.user.username);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = "";
  if (selectedWorkspace && selectedWorkspace.hasOwnProperty("workspace_id")) {
    workspaceId = selectedWorkspace.workspace_id;
  }
  useEffect(() => {
    axios
      .post(NEW_API_URL + "lessonplan/update_activity/", {
        user_id: username,
        topic_id: topic,
        activity_type: "timeline",
        activity_id: props.noteid || noteid,
        read_status: "Read",
      })
      .then((r) => {});
  }, []);

  const [timelineData, setTimelineData] = useState(undefined);
  const [maxPages, setMaxPages] = useState(2);

  const [noteDetail, setNoteDetail] = useState({
    pages: ["PG1" + markdown_str, "PG2" + markdown_str, "PG3" + markdown_str],
    qa: [
      { question: "aaaa", answer: "test" },
      { question: "bbbb", answer: "aa" },
    ],
  });

  const get_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL + "lessonplan/get-topic-materials/" + workspace + "/"
    );

    // setTopicDetail(r.data.topic_detail)

    return r.data;
  };

  const get_timeline_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL + "lessonplan/timeline/" + (props.noteid || noteid) + "/"
    );

    // setTopicDetail(r.data.topic_detail)
    setTimelineData(r.data);
    console.log(r.data, "timeline");

    return r.data;
  };

  useEffect(() => {
    get_map_data(username, topic);
    get_timeline_data(username, topic);
  }, []);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex
              mt={props.isConceptReader ? "0" : "10vh"}
              alignItems={"center"}
            >
              {/* <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginRight={'20px'} boxSize={'45px'}/> */}
              {!props.isConceptReader && <BackArrow />}

              <Box>
                <P2xl mb={0} ml={0} mr={0}>
                  <strong>{timelineData?.title}</strong>
                </P2xl>
                {/*<Pmd color={'#817D7D'} style={{margin:0}}><strong>History</strong></Pmd>*/}
              </Box>
            </Flex>
            <hr />

            <Container
              borderRadius={12}
              maxW={"container.xl"}
              backgroundColor={"white"}
            >
              {timelineData && <TimelineViz timelineData={timelineData} />}
            </Container>
          </Box>
        </Flex>
        <div style={{ height: "96px" }}></div>
      </Container>
    </>
  );
};

export default TimelineReading;
