"use client";

import {
  Box,
  Flex,
  useDisclosure,
  useColorModeValue,
  Stack,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import claydeckLogo from "../../assets/claydeckLogo.png";
import COLORS from "../../Components/colors";
import "./LandingPage.css";
import { Link, useNavigate } from "react-router-dom";

const Links = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Terms",
    link: "/tandc",
  },
  // {
  //   id: 3,
  //   name: "Subjective Quiz",
  //   link: "sub-quiz",
  // },
  {
    id: 4,
    name: "Login",
    link: "/signin",
  },

  {
    id: 5,
    name: "Join for Free",
    link: "/signup",
  },
];

const NavLink = (props) => {
  const { children } = props;

  return (
    <Link to={children.link}>
    <Box
      as="a"
      px={5}
      py={1}
      borderRadius={30}
      style={{
        backgroundColor: children.name === "Join for Free" ? COLORS.blue : null,
        color: children.name === "Join for Free" ? COLORS.offWhite : COLORS.black,
      }}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      className="cursor"
      // href={children.link}
    >
      <strong>{children.name}</strong>
    </Box>
    </Link>
  );
};

export default function Nav() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box bg={COLORS.white} px={75} py={2} className="landing-page-gradient">
        <Flex h={10} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <img
              src={claydeckLogo}
              alt="icon"
              onClick={() => navigate("/")}
              className="cursor"
              width={130}
              height={40}
            />
          </Box>
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={12}>
              <HStack
                as={"nav"}
                spacing={12}
                display={{ base: "none", md: "flex" }}
              >
                {Links.map((link) => (
                  <NavLink key={link.id}>{link}</NavLink>
                ))}
              </HStack>
              <IconButton
                size={"md"}
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                aria-label={"Open Menu"}
                display={{ md: "none" }}
                onClick={isOpen ? onClose : onOpen}
              />
            </Stack>
          </Flex>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
