import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex, Grid, GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import Plg from "../../Components/Typography/Text/Plg";
import P2xl from "../../Components/Typography/Text/P2xl";
import { Global } from "@emotion/react";
import { Alert } from "bootstrap";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  const [revisionModal,setRevisionModal] = useState(false)


  const [recoCard,setRecoCard] = useState( [
    {
      id: "1",
      title: "Understand connections through",
      subtitle: "Timelines",
      action_title: "Explore Southern Kingdoms timeline",
      action_cta: "Learn",
      action_cta_icon: "",
      bg_image:
          "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/webp/water_timeline.webp",
      character_image: "", //"https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/timeline_character.png",
      routeName: '',
      redirectionParams: {
        subject:'5ec6e09d-4855-493f-839d-50a61e0b7c6d',
        area:'52df1435-359d-4996-9794-3f298f003ca0',
        topic:'f3df1e47-6f18-4120-b038-93c6f5908f1a',
        type:'timeline',
        id:'50ab4027-1d2f-4473-99c2-b96c38b637b4'
      },
      routeParams: {
        id: "d47dd42e-39d4-4cd7-a26c-8cfa8cce0a2c",
        onTimelineRead: () => {},
      },
    },
    {
      id: "2",
      title: "Master concepts in a",
      subtitle: "Scenario game",
      action_title: "The Mughal Chronicles",
      action_cta: "Play",
      action_cta_icon: "",
      character_image: "", //"https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/game_character.png",
      bg_image:
          "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/webp/mughal_game.webp",
      routeName: '',
      redirectionParams: {
        subject:'5ec6e09d-4855-493f-839d-50a61e0b7c6d',
        area:'52df1435-359d-4996-9794-3f298f003ca0',
        topic:'706149e0-9b81-476f-9ec6-91e82bda5f25',
        type:'itools',
        id:'57e631ab-36bc-4f0d-aa7b-ea62b353b440'
      },
    },
    {
      id: "3",
      title: "Discover high-priority",
      subtitle: "News for UPSC",
      action_title: "Explore Claydeck's Trending News",
      action_cta: "Read",
      action_cta_icon: "",
      character_image: "", // "<https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/news_character.png>",
      bg_image:
          "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/webp/news_reco.webp",
      redirectionParams: {
        subject:'',
        area:'',
        topic:'',
        type:'trending_news',
        id:''
      },

    },
    {
      id: "4",
      title: "Access all your notes in one place",
      subtitle: "Notes",
      action_title: "View/Create your notes now",
      action_cta: "Create",
      action_cta_icon: "",
      character_image: "", // "<https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/notes_character.png>",
      bg_image:
          "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/webp/notes_reco.webp",
      redirectionParams: {
        subject:'',
        area:'',
        topic:'',
        type:'mynotes',
        id:''
      },
    },
  ])

  const resolveRedirectionParamsToLink = (redirectionParam)=> {
    if(redirectionParam.type==='mynotes')
      return '/mynotes'

    if(redirectionParam.type==='trending_news')
      return '/news?trending=true'

    if(redirectionParam.id && redirectionParam.id.length > 0)
      return '/learn/'+redirectionParam.subject+"/"+redirectionParam.area+"/"+redirectionParam.topic+"/"+redirectionParam.type+"/"+redirectionParam.id
    return '/learn/'+redirectionParam.subject+"/"+redirectionParam.area+"/"+redirectionParam.topic+"/"+redirectionParam.type+"/"
  }

  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }


  const ArrowLeft = (props) => (
      <Image src={'/arrowleft.png'} width={'20px'} height={'22px'} {...props}></Image>

  );
  const ArrowRight = (props) => (
      <Image src={'/arrowright.png'} width={'20px'} height={'22px'} {...props}></Image>
      // <button
      //     {...props}
      //     className={'next'}/>
  );



  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [


    ]
  };



  const [continueLearning,setContinueLearning] = useState([  ])




  const fetchHomeData = async () => {
    try {
      // setIsLoading(true);
      const res = await axios.get(
          `${NEW_API_URL}gamification/home_user_data/${username}/`
      );
      console.log(res.data);

      setContinueLearning(res.data.continue_learning);

      // setRecoCards(res.data.reco_cards);
      // setDailyTasks(res.data.daily_tasks);
      // setRecentTopics(res.data.continue_learning);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error("home data fetch failed", error);
    }
  };

  useEffect(()=>{
    fetchHomeData()
  },[])

  const sendFeedback = async (feedback, rating, toastNeeded=false) => {
    console.log('Feedback:', feedback);
    try {
      const postedData = {
        "userid": username,
        "rating": rating,
        "about": "app_home",
        "title": "",
        "description": feedback
      };
      const res = await axios.post(
        `${NEW_API_URL}community/leave_feedback/`,
        postedData
      );
      console.log('feedpack posting success', res.data)
      if (toastNeeded) {
        alert("Thank you for your feedback!");
            }
    } catch (error) {
      console.error('feedback posting failed,', error);
    }
  }

  const handleYesPress = (feedback) => {
    sendFeedback(feedback,5, true);
  };

  const handleNoPress = (feedback) => {
    sendFeedback(feedback,1, true);
  };

  const ScrollbarStyles = () => (
    <Global
      styles={`
        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
    />
  )



  return (
    <>
    <ScrollbarStyles/>
      <Container mt={'10vh'} justifyContent={'center'} alignItems={'center'} w={'90%'} maxW='container.xl' >

        <Flex mb={'36px'} mt={'24px'}>

          <Box width={'40%'} mb={'16px'}>
          <Slider {...settings} draggable={true} arrows={true} indicator={true}>

            {recoCard.map(item=>(
                <Box key={item} borderRadius={'12px'} overflow={'hidden'}
                     background={'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("'+item.bg_image+'") no-repeat center center'}
                     h={'250px'} w={'211px'}
                     mt={0}
                     backgroundSize={'cover'}
                >
                  <Container h={'100%'}>
                    <Flex flexDirection={'column'} justifyContent={'flex-start'} h={'100%'} pb={'12px'}>
                      <Flex mt={'15px'}>
                      <Box w={'3px'} bg={'white'} h={'100%'} mr={'10px'} ml={'5px'}>

                      </Box>
                      <Box>

                      <Psm fontWeight={'500'} margin={0} color={'claydeck.white.primary'}>{item.title}</Psm>
                      <Pmd margin={0} color={'claydeck.white.primary'}>{item.subtitle}</Pmd>
                      </Box>
                      </Flex>
                      <Pmd  color={'claydeck.white.primary'} mt={'100px'} mb={'12px'}ml={'8px'}>{item.action_title}</Pmd>
                      <Link to={resolveRedirectionParamsToLink(item.redirectionParams)}>
                      <ButtonPrimary bg={'white'} mt={0} w={'120px'} ml={'8px'} color={'black'}>Go</ButtonPrimary>
                      </Link>
                      {/*<Box backgroundColor={'#E9E9EB'} w={'100%'} h={'4px'} borderRadius={50} overflow={'hidden'}>*/}
                      {/*  <Box backgroundColor={'green'} w={item.percentageComplete} h={'4px'} borderRadius={50}></Box>*/}

                      {/*</Box>*/}
                    </Flex>
                  </Container>
                </Box>
            ))}
          </Slider>
          </Box>
          <Flex position={'relative'} justifyContent={'space-around'} borderRadius={'20px'} boxShadow={'0px 4px 4px 0px #00000040'} backgroundColor='#CAF4FF' ml={'30px'} width={'100%'} height={'250px'}>

            <Box position={'relative'} width={'300px'}>
            <Box position={'absolute'} top={'-20px'}>
          <Image src={'/welcomeimg.png'} width={'222px'} height={'217px'}/>
            </Box>
            </Box>

            <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <P2xl color={'#003D5F'} fontSize={'36px'} fontWeight={'700'}>Welcome to Claydeck</P2xl>

              {/*<Link to={'/learn'} style={{padding:'10px'}}>*/}
            <ButtonPrimary onClick={()=>{

              navigate("/learn")
            }}  width={'80%'} fontWeight={'300'}>
              <Image mr={'10px'} src={'/learnbook.png'} width={'20px'} height={'20px'}/>
              Start Learning

            </ButtonPrimary>
              {/*</Link>*/}
            </Flex>

          </Flex>

        </Flex>


        <Flex>
          <Box flex={1} padding={'10px'} mb={'24px'} >
        <Plg fontWeight={'700'}>Recent Topics</Plg>
        <HeadCard title={'Continue Learning'}
                  // titleButton={'Start a new topic'}
                  pb={'30px'}
                  shadow
                  border
                  minHeight={'70vh'}
                  maxHeight={'70vh'} overflowY={'auto'}
        >

          <Container maxW={'90%'}>
            {continueLearning.length === 0 && (
              <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: 4,
                  marginTop: 60,
                }}
              >
                <Image
                  style={{
                    width: 150,
                    // height: 250,
                    marginBottom: 20,
                  }}
                  src={"start_learning.webp"}
                />
                <Pmd
                  style={{
                    color: "rgba(160, 160, 160, 1)",
                    fontWeight: "400",
                    fontSize: 16,
                  }}
                >
                  Your recent topics will show here{" "}
                </Pmd>
              </div>
            </>
            )}
          {continueLearning.map(item=>(
              <Link to={'/learn/'+item.subject_id+"/area/"+item.topic_id+'/concepts'}>
              <Flex borderRadius={'12px'} bgColor={'#D3EDFF'} mt={'24px'} key={item} w={'100%'} boxShadow={'0px 4px 4px 0px #00000040'}>
                <Image borderRadius={'12px 0px 0px 12px'} src={item.topic_image ||  "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/medieval_history.jfif"} h={'170px'}></Image>
              {/*<Box  borderRadius={'12px'} overflow={'hidden'}*/}
              {/*     background={'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("'+item.topic_image+'") no-repeat center center'}*/}
              {/*     h={'100%'} w={'211px'}*/}
              {/*     mt={10}*/}
              {/*     backgroundSize={'cover'}*/}
              {/*>*/}
              {/*</Box>*/}
                <Box ml={'15px'} px={'10px'} mt={'10px'} w={'100%'}>
                  <Flex flexDirection={'column'} justifyContent={'flex-start'} w={'100%'} h={'100%'} pb={'12px'}>
                    <Pxl margin={0} color={'#323232'}>{item.topic_name}</Pxl>
                    <Pmd margin={0} color={'#747474'}>{item.subject_name}</Pmd>
                    <Box backgroundColor={'#BDBDBD'} w={'100%'} h={'4px'} borderRadius={50} overflow={'hidden'}>
                      <Box backgroundColor={'#413939'} w={item.percentageComplete} h={'4px'} borderRadius={50}></Box>

                    </Box>

                    <Flex justifyContent={'flex-end'}>
                    <Box mt={'10px'}>
                    <ButtonPrimary fontWeight={'300'}>Go</ButtonPrimary>
                    </Box>
                    </Flex>
                  </Flex>
                </Box>

              </Flex>
              </Link>
          ))}
          </Container>

        </HeadCard>
          </Box>
          <Box flex={0.5} padding={'10px'}>
            <Plg fontWeight={'700'}>Daily Tasks</Plg>
            <HeadCard title={'Master your skills'}
                // titleButton={'Start a new topic'}
                      pb={'30px'}
                      shadow
                      border
                      minHeight={'70vh'}
                      maxHeight={'70vh'} overflowY={'auto'}

            >

              <Center
                  justifyContent={'flex-start'} mt={10} pb={'20px'} px={'20px'}>

                <Wrap>
                  <WrapItem>
                    {/* <Link style={{marginRight:'25px'}} to={'/learn/history/geo/eco/notes/123?home=1'}> */}
                      <Box onClick={()=>setRevisionModal(true)} cursor={'pointer'}>
                      <Center bgColor={'#E4FAFF'} w={'20vw'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'}>
                        <Image src={'/reading_book.png'} h={'80px'} w={'80px'}></Image>
                        {/*<Box w={'25px'} h={'25px'} bgColor={'red'} position={'absolute'} borderRadius={'50px'} top={-2} right={-2}></Box>*/}
                        <Psm pt={2} px={8} textAlign={'center'}>Personalised revision</Psm>

                      </Center>
                      </Box>
                    {/* </Link> */}
                  </WrapItem>
                  <WrapItem>
                    <Link to={'/news'}>
                    <Center  mr={'25px'} bgColor={'#C7E5FF'} w={'20vw'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'} >
                      <Image src={'/daily_news.png'} h={'80px'} w={'80px'}></Image>
                      {/*<Box w={'25px'} h={'25px'} bgColor={'red'} position={'absolute'} borderRadius={'50px'} top={-2} right={-2}></Box>*/}

                      <Psm pt={2} px={8} textAlign={'center'}>Daily News</Psm>

                    </Center>
                    </Link>
                  </WrapItem>
                  {/* <WrapItem>
                    <Center  mr={'25px'} bgColor={'#E8EBFF'} w={'170px'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'}>
                      <Image src={'/daily_test.png'} h={'80px'} w={'80px'}></Image>


                      <Psm pt={2} px={8} textAlign={'center'}>Daily Test</Psm>

                    </Center>
                  </WrapItem> */}
                </Wrap>






              </Center>

            </HeadCard>
          </Box>
          {/*<Box flex={0.5} padding={'10px'}>*/}
          {/*  <Plg fontWeight={'700'}>Quick Access</Plg>*/}
          {/*  <HeadCard title={'Notes'}*/}
          {/*            bgColor={'#FFF9D0'}*/}
          {/*      // titleButton={'Start a new topic'}*/}
          {/*            pb={'30px'}*/}
          {/*            shadow*/}
          {/*            border*/}
          {/*  >*/}
          {/*    /!*<Flex justifyContent={'space-around'} alignItems={'center'} mt={'15px'}>*!/*/}




          {/*    /!*</Flex>*!/*/}

          {/*    /!*<Center mt={10} pb={5}>*!/*/}
          {/*    /!*<ButtonPrimary link={'/learn'} fontWeight={'400'}>*!/*/}
          {/*    /!*  <Image src={'/learning.png'} w={'20px'} mr={'10px'}/>*!/*/}
          {/*    /!*  Start a new topic</ButtonPrimary>*!/*/}
          {/*    /!*</Center>*!/*/}

          {/*  </HeadCard>*/}
          {/*</Box>*/}
        </Flex>
        <HeadCard shadow border mb={'96px'} mx={2}>
          <Box p={4}>
            <Flex alignItems="center" gap={4} flexDirection={'column'}>
              <Text fontSize="md" fontWeight={'800'}>Like using Claydeck?</Text>
              <HStack spacing={4}>
              <ButtonPrimary
              width={'200px'}
                onClick={() => {
                  const feedback = prompt("What do you like about Claydeck?");
                  if (feedback) {
                    handleYesPress(feedback);
                  }
                }}
              >
                Yes
              </ButtonPrimary>
              <ButtonSecondary
              width={'200px'}
                onClick={() => {
                  const feedback = prompt("What can we improve?");
                  if (feedback) {
                    handleNoPress(feedback);
                  }
                }}
              >
                No
              </ButtonSecondary>
              </HStack>
            </Flex>
          </Box>
        </HeadCard>

      </Container>
      <CustomStyledModal
          isOpen={revisionModal}
          onClose={()=>setRevisionModal(false)}
          title={'Personalised Revision'}
          description={"A personalised list of notes as per your reading history will be shown here once you have read enough notes under Topics"}
          buttonTitle={'Close'}
          buttonAction={()=>setRevisionModal(false)}
      />
      {/*<CustomStyledModal*/}
      {/*    subscription_new*/}
      {/*    isOpen={true}*/}
      {/*    onClose={()=>setRevisionModal(false)}*/}
      {/*    buttonTitle={'Close'}*/}
      {/*    buttonAction={()=>setRevisionModal(false)}*/}
      {/*/>*/}
      </>

  );
}

export default Home;
