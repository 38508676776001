import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import { quiz_map } from "../../data/new_generated/quiz_map";
import EmptyImage from "../../Components/EmptyImage";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
import { featureFlags } from "../../featureflags";
const Tests = () => {
  const [tab, setTab] = useState("personalized");
  const [personalizedTests, setPersonalizedTests] = useState([]);
  const [pyqTests, setPyqTests] = useState([]);
  const [practiceTests, setPracticeTests] = useState([]);
  // const [standardTests, setStandardTests] = useState(quiz_map.standard_tests);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [subjectData, setSubjectData] = useState([]);
  const [subjectSelect, setSubjectSelect] = useState({});
  const username = useSelector((state) => state.user.username);

  const [topicDetail, setTopicDetail] = useState({});

  const get_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL + "lessonplan/get-topic-materials/" + workspace + "/"
    );

    setTopicDetail(r.data.topic_detail);

    if (r?.data) {
      let tests = r.data.topic_detail.tests;
      let pyq_tests = r.data.topic_detail.pyq_tests;
      setPyqTests(pyq_tests);
      setPracticeTests(tests);
    }

    return r.data;
  };

  const get_lesson_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_lessonplan/" +
        username +
        "/" +
        workspace +
        "/"
    );
    console.log(r);
    setSubjectData(r.data);
    // return r.data
  };
  useEffect(() => {
    get_lesson_map_data(username, 1);
  }, []);
  useEffect(() => {
    if (subjectSelect && subjectSelect.topic) {
      console.log(subjectSelect);
      get_map_data(username, subjectSelect.topic.topic_id);
    }
  }, [subjectSelect]);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        mt={"100px"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex alignItems={"center"}>
              <Box>
                <P2xl mb={0} ml={0} mr={0}>
                  <strong>
                    Test{" "}
                    {subjectSelect.hasOwnProperty("topic") ? (
                      <>- {subjectSelect.topic.topic_name}</>
                    ) : (
                      <></>
                    )}
                  </strong>
                </P2xl>
              </Box>
            </Flex>

            <Tabs align={"center"}>
              <TabList>
                <Tab>
                  <Image h={"20px"} w={"20px"} src={"/hintai.png"}></Image>

                  <Pxl
                    style={{ fontWeight: "300" }}
                    ml={"30px"}
                    mr={"30px"}
                    mb={0}
                    cursor={"pointer"}
                    onClick={() => {
                      setTab("personalized");
                    }}
                    color={tab == "personalized" ? "#0962A2" : "black"}
                  >
                    Personalised
                  </Pxl>
                </Tab>
                <Tab>
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <Pxl
                      m={0}
                      style={{ fontWeight: "300" }}
                      ml={"10px"}
                      mr={"30px"}
                      cursor={"pointer"}
                      onClick={() => {
                        setTab("pyq");
                      }}
                      color={tab == "pyq" ? "#0962A2" : "black"}
                    >
                      PYQs
                    </Pxl>
                  </Flex>
                </Tab>
                <Tab>
                  <Pxl
                    style={{ fontWeight: "300" }}
                    ml={"30px"}
                    mb={0}
                    mr={"30px"}
                    cursor={"pointer"}
                    onClick={() => {
                      setTab("practice");
                    }}
                    color={tab == "practice" ? "#0962A2" : "black"}
                  >
                    Practice
                  </Pxl>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {featureFlags.adaptiveTests && (
                    <HeadCard
                      py={"20px"}
                      w={"37%"}
                      style={{ borderLeft: "2px solid #0962A2" }}
                    >
                      <Flex justifyContent={"center"} alignItems={"center"}>
                        <Box>
                          <Plg>
                            <strong>Adaptive Testing Module</strong>
                          </Plg>
                          {/*<Psm style={{color:'#4D4B4B'}}>100 questions with answers</Psm>*/}
                          <Link to={"/news/topicDetail/questions/prelims/"}>
                            <ButtonSecondary mr={"15px"}>
                              History
                            </ButtonSecondary>
                          </Link>
                          {/*<Link to={'/news/topicDetail/questions/prelims/'}>*/}
                          <ButtonPrimary onClick={onOpen}>
                            Start Test
                          </ButtonPrimary>
                          {/*</Link>*/}
                        </Box>
                        <Image src={"/adaptivetest.png"} h={"150px"}></Image>
                      </Flex>
                    </HeadCard>
                  )}
                  {!featureFlags.adaptiveTests && (
                    <EmptyImage message={"Coming Soon"} />
                  )}
                </TabPanel>
                <TabPanel>
                  <Box mt={"16px"} position={"relative"} zIndex={10}>
                    <HeadCard title={"Topic-wise"}>
                      <Flex
                        justifyContent={"flex-start"}
                        mt={"16px"}
                        position={"relative"}
                        zIndex={10}
                      >
                        <Box padding={"20px"}>
                          <MultiLayerDropdown
                            onSelect={(e) => {
                              setSubjectSelect(e);
                            }}
                            data={subjectData}
                            text={"Change Topic"}
                          />
                        </Box>
                      </Flex>

                      <Flex
                        ml={"12px"}
                        pb={"32px"}
                        pl={"32px"}
                        flexWrap={"wrap"}
                      >
                        {pyqTests.map((item, index) => (
                          <Link
                            to={
                              "/learn/" +
                              subjectSelect.subject.subject_id +
                              "/" +
                              subjectSelect.area.area_id +
                              "/" +
                              subjectSelect.topic.topic_id +
                              "/tests/" +
                              item.id
                            }
                            state={{
                              leaderboard: false,
                            }}
                          >
                            <Flex
                              position={"relative"}
                              mr={"20px"}
                              key={index}
                              flexDirection={"column"}
                              mt={"30px"}
                              w={"200px"}
                              p={"12px"}
                              boxShadow={"0px 3px 3px 0px #00000040"}
                              borderRadius={"12px"}
                              borderLeft={"4px solid #2A6291"}
                              minH={"100px"}
                            >
                              {item.read_status === "Read" ||
                                (item.read_status === "read" && (
                                  <Image
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "10px",
                                    }}
                                    src={"/read_tick.png"}
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                ))}

                              <Flex
                                justify={"space-around"}
                                alignItems={"center"}
                              >
                                <Box>
                                  <Center>
                                    <Image
                                      height={"64px"}
                                      width={"53px"}
                                      src={"/news_test.png"}
                                    ></Image>
                                  </Center>

                                  {/*<Flex alignItems={'center'}>*/}
                                  {/*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*/}
                                  {/*</Flex>*/}
                                  <Box mt={"10px"}>
                                    <Pmd mb={0} textAlign={"center"}>
                                      {item.title}
                                    </Pmd>
                                  </Box>
                                </Box>
                                {item.read_status === "Read" ||
                                  (item.read_status === "read" && (
                                    <Pmd>
                                      {item.score.toFixed(1)} / {item.max_score}
                                    </Pmd>
                                  ))}
                              </Flex>
                            </Flex>
                          </Link>
                        ))}
                      </Flex>
                    </HeadCard>
                  </Box>

                  {/*{*/}
                  {/*    pyqTests.length <= 0  && <EmptyImage message={'No tests available yet'}/>*/}
                  {/*}*/}
                </TabPanel>
                <TabPanel>
                  <Box mt={"16px"} position={"relative"} zIndex={10}>
                    <HeadCard title={"Topic-wise"}>
                      <Flex
                        justifyContent={"flex-start"}
                        mt={"16px"}
                        position={"relative"}
                        zIndex={10}
                      >
                        <Box padding={"20px"}>
                          <MultiLayerDropdown
                            onSelect={(e) => {
                              setSubjectSelect(e);
                            }}
                            data={subjectData}
                            text={"Change Topic"}
                          />
                        </Box>
                      </Flex>

                      <Flex
                        ml={"12px"}
                        pb={"32px"}
                        pl={"32px"}
                        flexWrap={"wrap"}
                      >
                        {practiceTests.map((item, index) => (
                          <Link
                            to={
                              "/learn/" +
                              subjectSelect.subject.subject_id +
                              "/" +
                              subjectSelect.area.area_id +
                              "/" +
                              subjectSelect.topic.topic_id +
                              "/tests/" +
                              item.id
                            }
                            state={{
                              leaderboard: false,
                            }}
                          >
                            <Flex
                              position={"relative"}
                              mr={"20px"}
                              key={index}
                              flexDirection={"column"}
                              mt={"30px"}
                              w={"200px"}
                              p={"12px"}
                              boxShadow={"0px 3px 3px 0px #00000040"}
                              borderRadius={"12px"}
                              borderLeft={"4px solid #2A6291"}
                              minH={"150px"}
                            >
                              {item.read_status === "Read" && (
                                <Image
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                  }}
                                  src={"/read_tick.png"}
                                  height={"20px"}
                                  width={"20px"}
                                />
                              )}

                              <Flex
                                justify={"space-around"}
                                alignItems={"center"}
                              >
                                <Box>
                                  <Center>
                                    <Image
                                      height={"64px"}
                                      width={"53px"}
                                      src={"/news_test.png"}
                                    ></Image>
                                  </Center>

                                  {/*<Flex alignItems={'center'}>*/}
                                  {/*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*/}
                                  {/*</Flex>*/}
                                  <Box mt={"10px"}>
                                    <Pmd mb={0} textAlign={"center"}>
                                      {item.title}
                                    </Pmd>
                                  </Box>
                                </Box>
                                {item.read_status === "Read" ||
                                  (item.read_status === "read" && (
                                    <Pmd>
                                      {item.score.toFixed(1)} / {item.max_score}
                                    </Pmd>
                                  ))}
                              </Flex>
                            </Flex>
                          </Link>
                        ))}
                      </Flex>
                    </HeadCard>
                  </Box>
                  {practiceTests.length <= 0 && (
                    <EmptyImage message={"No tests available yet"} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"12px"}
          borderTop={"4px solid #0962A2"}
          maxWidth={"container.lg"}
        >
          <ModalHeader textAlign={"center"}>
            Welcome to Claydeck’s Adaptive Testing
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Pmd>
              These tests are designed to help you to consistently improve your
              UPSC preparation and test scores.
              <br />
              <br />
              New tests will keep becoming available regularly and will be
              adapted based on your performance throughout the app
              <br />
              <br />
              During a test, if you get a question wrong, you can use the{" "}
              <strong>Claydeck Compass</strong> to understand the concept
              better. A variation of such questions will be repeated in future
              tests to strengthen your recall
            </Pmd>
          </ModalBody>
          <ModalFooter textAlign={"center"} justifyContent={"center"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                window.location.href =
                  "/learn/d135126f-d782-446a-8480-8adf27981c2f/d3bf71cf-0fd7-4563-b33c-634e0f868d9e/36e2b656-fdff-4ec5-ad85-111d886b0034/tests/bc64ed6d-8e5c-4b59-b2d1-3ab61b68c561";
              }}
            >
              Start
            </Button>
            {/*<Button*/}
            {/*    colorScheme="red"*/}
            {/*    mr={3}*/}
            {/*    onClick={() => {*/}
            {/*      localStorage.clear();*/}
            {/*      window.location.reload();*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Logout*/}
            {/*</Button>*/}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Tests;
