import React from "react";
import { Box, Heading, Text, Button, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      <VStack spacing={6} textAlign="center" p={8}>
        <Heading size="4xl" color="gray.400">
          404
        </Heading>
        <Heading size="xl" color="gray.600">
          Page Not Found
        </Heading>
        <Text color="gray.500" maxW="md">
          The page you're looking for doesn't exist or has been moved.
        </Text>
        <Button
          colorScheme="blue"
          size="lg"
          onClick={() => navigate("/")}
        >
          Return Home
        </Button>
      </VStack>
    </Box>
  );
};

export default NotFound;
