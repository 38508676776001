import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


import {
  Box,
  Grid,
  GridItem,
  Checkbox,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Center, Text, Spinner,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URL_STAGING, NEW_API_URL } from "../../redux/types";
import { loginUser, signupUser } from "../../redux/actions/userActions";
import { datadogLogs } from '@datadog/browser-logs';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Plg from "../../Components/Typography/Text/Plg";
import Psm from "../../Components/Typography/Text/Psm";
import ButtonSocial from "../../Components/Button/ButtonSocial";
import Pxl from "../../Components/Typography/Text/Pxl";
import { GoogleLogin } from "@react-oauth/google";
import COLORS from "../../Components/colors";
import IntroCarousel from "./unused/IntroCarousel";
import { AuthCarousel } from "./components/AuthComponents";
import { validateEmail, SCREEN_BREAKPOINT } from "./utils/authUtils";

const FORGOT_PASSWORD_STATES = {
  LOGIN: 0,
  EMAIL_INPUT: 1,
  EMAIL_SENT: 2,
  NEW_PASSWORD: 3
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginLoading = useSelector(state => state.UI.failedSignin);

  // Form States
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    rememberMe: false
  });

  // UI States
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [forgotPasswordState, setForgotPasswordState] = useState(FORGOT_PASSWORD_STATES.LOGIN);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Screen resize handler
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Error handling
  useEffect(() => {
    if (loginLoading) {
      setError('Unable to login with the provided email/password');
    }
  }, [loginLoading]);

  // Form handlers
  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    if (screenWidth < SCREEN_BREAKPOINT) {
      setError("Please open this page on a bigger screen or download our app");
      return;
    }

    if (!validateEmail(formState.email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsSigningIn(true);
    const userData = {
      username: formState.email,
      password: formState.password,
    };

    datadogLogs.logger.info('Signin', {
      name: 'Signin button clicked',
      id: 123,
      responseTime: Date.now()
    });

    dispatch(loginUser(userData, formState.rememberMe, navigate));
    setIsSigningIn(false);
  };

  const handleGoogleSuccess = (response) => {
    const profile = JSON.parse(atob(response.credential.split(".")[1]));
    const userData = {
      username: profile.email.toLowerCase(),
      email: profile.email,
      password: profile.email + profile.sub,
      first_name: profile.given_name,
      last_name: profile.family_name,
      idToken: response.credential,
      authProvider: 'google'
    };
    dispatch(loginUser(userData, formState.rememberMe, navigate));
  };

  const handlePasswordReset = async () => {
    if (!validateEmail(formState.email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${NEW_API_URL}user/send-reset-password-email/`, {
        username: formState.email,
      });
      setForgotPasswordState(FORGOT_PASSWORD_STATES.EMAIL_SENT);
    } catch (error) {
      setError("Failed to send reset email. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render functions
  const renderLoginForm = () => (
    <Box>
      <Grid gap="32px">
        <GridItem>
          <InputOutline
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <InputPassword
            name="password"
            value={formState.password}
            onChange={handleInputChange}
          />
        </GridItem>
      </Grid>

      {error && (
        <Psm color="red.500" mt={2}>
          {error}
        </Psm>
      )}

      <Psm color="white" mt={5} textAlign="right" fontWeight="400">
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => setForgotPasswordState(FORGOT_PASSWORD_STATES.EMAIL_INPUT)}
        >
          Forgot Password?
        </span>
      </Psm>

      <ButtonPrimary
        width="100%"
        color="white"
        bg="claydeck.brand.primary"
        marginBottom={0}
        isLoading={isSigningIn}
        loadingText="Signing In"
        onClick={handleSubmit}
      >
        {isSigningIn ? <Spinner /> : 'Log in'}
      </ButtonPrimary>

      <Flex alignItems="center" justifyContent="center" marginTop={3}>
        <hr style={{ width: '40%', border: '1px solid #838383' }} />
        <Psm margin={0} color="white" mx="20px">Or</Psm>
        <hr style={{ width: '40%', border: '1px solid #838383' }} />
      </Flex>

      <Flex justifyContent="center" mt="10px" direction="column" alignItems="center">
        {screenWidth > SCREEN_BREAKPOINT && (
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => setError('Google login failed')}
          />
        )}
        <hr style={{ width: '100%', border: '1px solid #838383', marginTop: '48px' }} />
        <Pmd
          cursor="pointer"
          color="white"
          mt={5}
          mb={0}
          fontWeight="400"
          fontSize="20px"
        >
          <span
            style={{ color: '#75BFF5' }}
            onClick={() => navigate('/signup')}
          >
            Create an Account
          </span>
        </Pmd>
      </Flex>
    </Box>
  );

  const renderForgotPasswordForm = () => (
    <Box>
      <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
        Forgot Password
      </Pxl>
      <Psm color="#CCCCCC" mt={0}>
        Enter the email address you used to create the account, and we will email you instructions to reset the password
      </Psm>

      <Grid gap="32px">
        <GridItem>
          <Pmd color="white" mb="16px">Email Address</Pmd>
          <InputOutline
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleInputChange}
          />
        </GridItem>
      </Grid>

      {error && (
        <Psm color="red.500" mt={2}>
          {error}
        </Psm>
      )}

      <ButtonPrimary
        width="100%"
        mt="20px"
        color="white"
        isDisabled={isLoading}
        bg="claydeck.brand.primary"
        marginBottom={0}
        onClick={handlePasswordReset}
      >
        Send Email
      </ButtonPrimary>

      <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
        Remember Password?{' '}
        <span
          style={{ color: '#75BFF5', cursor: 'pointer' }}
          onClick={() => setForgotPasswordState(FORGOT_PASSWORD_STATES.LOGIN)}
        >
          Login
        </span>
      </Pxl>
    </Box>
  );

  const renderEmailSentConfirmation = () => (
    <Box>
      <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
        Email Sent
      </Pxl>
      <Psm color="#CCCCCC" mt={0}>
        We have sent an email to <strong>{formState.email}</strong> with instructions to reset the password
      </Psm>

      <Pmd
        cursor="pointer"
        color="white"
        mt={5}
        mb={0}
        textAlign="left"
        fontWeight="400"
        onClick={handlePasswordReset}
      >
        Didn't receive the mail? <span style={{ color: '#75BFF5' }}>Resend</span>
      </Pmd>
      <Pmd
        cursor="pointer"
        color="white"
        mt={5}
        mb={0}
        textAlign="left"
        fontWeight="400"
        onClick={() => setForgotPasswordState(FORGOT_PASSWORD_STATES.EMAIL_INPUT)}
      >
        Wrong Email Address? <span style={{ color: '#75BFF5' }}>Change Email Address</span>
      </Pmd>
    </Box>
  );

  const renderContent = () => {
    switch (forgotPasswordState) {
      case FORGOT_PASSWORD_STATES.EMAIL_INPUT:
        return renderForgotPasswordForm();
      case FORGOT_PASSWORD_STATES.EMAIL_SENT:
        return renderEmailSentConfirmation();
      default:
        return renderLoginForm();
    }
  };

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
    >
      {screenWidth > SCREEN_BREAKPOINT && <AuthCarousel />}
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgColor="claydeck.brand.secondary"
      >
        <Box
          height="100%"
          width={{ base: "100%", lg: "50%" }}
          maxWidth="620px"
          display="flex"
          gap={{ base: "32px", md: "0px" }}
          flexDirection="column"
          justifyContent="space-evenly"
          padding={{ base: "16px", md: "32px", lg: "0px" }}
        >
          {renderContent()}
        </Box>
      </GridItem>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalBody>
            <Pmd
              color={"claydeck.text.primary"}
              letterSpacing={"0.2px"}
              mb={"16px"}
            >
              You will recieve a password reset link on the email you enter
              below
            </Pmd>
            <InputOutline
              type="email"
              name="reset-password-email"
              placeholder={"example@email.com"}
              value={formState.email}
              onChange={(event) =>
                setFormState(prev => ({
                  ...prev,
                  email: event.currentTarget.value
                }))
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </ButtonPrimary>
            <ButtonSecondary variant="ghost" onClick={handlePasswordReset}>
              Send Email
            </ButtonSecondary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default SignIn;
