import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { NEW_API_URL } from "../redux/types";
import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import Markdown from "react-markdown";
import Pmd from "./Typography/Text/Pmd";
import ButtonText from "./Button/ButtonText";
import { HighlightMenu } from "react-highlight-menu";
import CustomStyledModal from "./Modal/CustomStyledModal";
import ButtonSecondary from "./Button/ButtonSecondary";
import ButtonPrimary from "./Button/ButtonPrimary";
import TimelineViz from "./TimelineViz";
import TestsQuiz from "../Pages/home/TestsQuiz";
import IToolsReading from "../Pages/home/IToolsReading";
import NewsReading from "../Pages/home/NewsReading";
import NotesReading from "../Pages/home/NotesReading";
import TimelineReading from "../Pages/home/TimelineReading";
import FlowComp from "../Pages/FlowComp";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ConceptReader(props) {
  const { setActiveConcept, activeConcept, conceptDetail } = props;
  const username = useSelector((state) => state.user.username);
  const navigate = useNavigate();

  const [readerData, setReaderData] = useState(undefined);

  const [nextState, setNextState] = useState(0);
  const [prevState, setPrevState] = useState(0);

  const [isFinish, setIsFinish] = useState(false);

  const handleUpdateReadStatus = useCallback(
    async (
      user_id,
      topic_id,
      activity_type,
      activity_id,
      read_status,
      concept_id
    ) => {
      try {
        const postedData = {
          user_id,
          topic_id: String(topic_id),
          activity_type,
          activity_id,
          read_status,
          concept_id: String(concept_id),
        };
        const res = await axios.post(
          NEW_API_URL + `lessonplan/update_activity/`,
          postedData
        );
        console.log(
          "Success: read content at index:",
          activeConcept,
          " response:",
          res?.data
        );
      } catch (error) {
        console.log(
          "Error updating read status at index:",
          activeConcept,
          error
        );
      }
    },
    [activeConcept]
  );

  const getCurrentConceptData = () => {
    const current = conceptDetail.contents[activeConcept];
    console.log("Current concept:", current);

    if (current.type === "note") {
      axios
        .get(
          NEW_API_URL + "notes/get_note/admin@claydeck.com/" + current.id + "/"
        )
        .then((r) => {
          setReaderData(r.data);
        });
      return;
    }

    if (current.type === "timeline") {
      axios
        .get(NEW_API_URL + "lessonplan/timeline/" + current.id + "/")
        .then((r) => {
          setReaderData(r.data);
        });
      return;
    }

    setReaderData(true);
  };

  useEffect(() => {
    if (conceptDetail && conceptDetail.contents) {
      getCurrentConceptData();
    }
  }, [activeConcept, conceptDetail]);

  const isRestricted = (concept) =>
    ["quiz", "itool", "news", "timeline", "note", "map"].includes(concept.type);

  const isPagingType = (concept) =>
    concept.type === "quiz" || concept.type === "itool";

  const isLast = () => {
    if (activeConcept === conceptDetail.contents.length - 1) {
      const cur = conceptDetail.contents[activeConcept];
      return isPagingType(cur) ? isFinish : true;
    }
    return false;
  };

  useEffect(() => {
    if (!conceptDetail || !conceptDetail.contents) return;
    const currentConcept = conceptDetail.contents[activeConcept];

    if (isPagingType(currentConcept) && isFinish) {
      handleUpdateReadStatus(
        username,
        conceptDetail.topic_id,
        "content",
        currentConcept.id,
        "Read",
        conceptDetail.id
      )
        .then(() => {
          setActiveConcept((prev) => prev + 1);

          setIsFinish(false);
          setNextState(0);
          setPrevState(0);
        })
        .catch((err) => {
          console.error("Error marking read status in useEffect:", err);
        });
    }
  }, [
    isFinish,
    activeConcept,
    conceptDetail,
    handleUpdateReadStatus,
    username,
  ]);

  const getNotesReader = () => (
    <NotesReading
      isConceptReader
      noteid={conceptDetail.contents[activeConcept].id}
    />
  );

  const getTimelineReader = () => (
    <TimelineReading
      isConceptReader
      noteid={conceptDetail.contents[activeConcept].id}
    />
  );

  const getQuizReader = () => (
    <TestsQuiz
      next={nextState}
      prev={prevState}
      finish={setIsFinish}
      isConceptReader
      noteid={conceptDetail.contents[activeConcept].id}
    />
  );

  const getItoolReader = () => (
    <IToolsReading
      next={nextState}
      prev={prevState}
      finish={setIsFinish}
      isConceptReader
      noteid={conceptDetail.contents[activeConcept].id}
    />
  );

  const getNewsReader = () => (
    <NewsReading
      isConceptReader
      noteid={conceptDetail.contents[activeConcept].id}
    />
  );

  const getMapsReader = () => <FlowComp />;

  if (!conceptDetail || !readerData) {
    return <Spinner />;
  }

  return (
    <Box>
      <Box
        overflowY="auto"
        background="#FFFFFF"
        borderRadius="16px"
        mt={4}
        mb={2}
        p={0}
      >
        {conceptDetail.contents[activeConcept].type === "note" &&
          getNotesReader()}
        {conceptDetail.contents[activeConcept].type === "timeline" &&
          getTimelineReader()}
        {conceptDetail.contents[activeConcept].type === "quiz" &&
          getQuizReader()}
        {conceptDetail.contents[activeConcept].type === "itool" &&
          getItoolReader()}
        {conceptDetail.contents[activeConcept].type === "news" &&
          getNewsReader()}
        {conceptDetail.contents[activeConcept].type === "map" &&
          getMapsReader()}
      </Box>

      <Flex
        alignItems="center"
        justifyContent="space-around"
        position="fixed"
        bottom={0}
        left="28%"
        width="56%"
      >
        <ButtonSecondary
          isDisabled={activeConcept <= 0}
          style={{
            margin: 10,
            width: "40%",
            position: "relative",
            zIndex: 10,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
          }}
          onClick={() => {
            // If we’re moving from one restricted content to another...
            if (
              !isRestricted(conceptDetail.contents[activeConcept - 1]) &&
              !isRestricted(conceptDetail.contents[activeConcept])
            ) {
              setReaderData(undefined);
            }

            // If current content is not restricted, just go back
            if (!isRestricted(conceptDetail.contents[activeConcept])) {
              setActiveConcept(activeConcept - 1);
            } else {
              // If we’re mid “quiz / iTool steps”
              if (Math.abs(prevState - nextState) === 0) {
                // That means we’re at the first step of that content
                setActiveConcept(activeConcept - 1);

                if (!isRestricted(conceptDetail.contents[activeConcept - 1])) {
                  setReaderData(undefined);
                }
                setPrevState(0);
                setNextState(0);
              } else {
                setPrevState(prevState + 1);
                setIsFinish(false);
              }
            }
          }}
        >
          Previous
        </ButtonSecondary>

        <ButtonPrimary
          style={{
            margin: 10,
            width: "40%",
            position: "relative",
            zIndex: 10,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
          }}
          onClick={async () => {
            try {
              // If this is the last piece of content overall
              if (isLast()) {
                await handleUpdateReadStatus(
                  username,
                  conceptDetail.topic_id,
                  "content",
                  conceptDetail.contents[activeConcept].id,
                  "Read",
                  conceptDetail.id
                );

                props.isLast();
                return;
              }

              // If multi-step content
              if (isPagingType(conceptDetail.contents[activeConcept])) {
                if (isFinish) {
                  await handleUpdateReadStatus(
                    username,
                    conceptDetail.topic_id,
                    "content",
                    conceptDetail.contents[activeConcept].id,
                    "Read",
                    conceptDetail.id
                  );

                  setReaderData(undefined);
                  setActiveConcept(activeConcept + 1);
                  setIsFinish(false);
                  setPrevState(0);
                  setNextState(0);
                } else {
                  setNextState(nextState + 1);
                }
              } else {
                // Single-page content
                await handleUpdateReadStatus(
                  username,
                  conceptDetail.topic_id,
                  "content",
                  conceptDetail.contents[activeConcept].id,
                  "Read",
                  conceptDetail.id
                );

                setReaderData(undefined);
                setActiveConcept(activeConcept + 1);
              }
            } catch (error) {
              console.error("Error in Next button handler:", error);
            }
          }}
        >
          {isLast() ? "Finish" : "Next"}
        </ButtonPrimary>
      </Flex>
    </Box>
  );
}

export default ConceptReader;
