import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
  Text,
  Spinner,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL, NEW_API_URL_DEV } from "../../redux/types";
import { useNavigate, useParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import EmptyImage from "../../Components/EmptyImage";
import BackArrow from "../../Components/backArrow";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
import { CircularProgressbar } from "react-circular-progressbar";
import conceptData from "../../../src/data/dummyConceptDetails.json";

//dummy data for concept list
const dummyData = [
  {
    id: "12dae3ab-782c-42b9-a1d1-34939eeff7ae",
    title: "History of Indian Constitution",
    icon_image_link: "",
    completion_rate: 25,
    priority: "medium",
    last_updated: "",
  },
  {
    id: "2cb67efd-5cac-415b-8867-3a4fd6beb982",
    title: "Key Points in the Evolution of the Indian Constitution",
    icon_image_link: "",
    completion_rate: 12,
    priority: "medium",
    last_updated: "",
  },
  {
    id: "99577a51-0650-4681-a561-82aa0e85925d",
    title: "The Constituent Assembly of India",
    icon_image_link: "",
    completion_rate: 0,
    priority: "medium",
    last_updated: "",
  },
  {
    id: "3ae1ee38-633e-4393-be2d-e48cb248181d",
    title: "Adoption of the Constitution",
    icon_image_link: "",
    completion_rate: 100,
    priority: "medium",
    last_updated: "",
  },
];

const TopicConcept = (props) => {
  const { isTimeline } = props;
  console.log(isTimeline, "is_timeline_thing");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { topic, subject, area } = useParams();
  console.log("topic:", topic);
  const notesModalStates = useDisclosure();

  const newUser = useSelector((state) => state.user.newUser);
  const username = useSelector((state) => state.user.username);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = "";
  if (selectedWorkspace && selectedWorkspace.hasOwnProperty("workspace_id")) {
    workspaceId = selectedWorkspace.workspace_id;
  }
  console.log("workspace_ID timeline", workspaceId, selectedWorkspace, user);

  const [noteType, setNoteType] = useState("All Notes");
  const [topicDetail, setTopicDetail] = useState({});
  const [timelinesDetail, setTimelinesDetail] = useState([]);
  const [notesDetail, setNotesDetail] = useState([]);
  const [notesDetailOrigial, setNotesDetailOriginal] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subjectData, setSubjectData] = useState([]);
  const [conceptLists, setConceptLists] = useState([]);

  const get_lesson_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_lessonplan/" +
        username +
        "/" +
        workspace +
        "/"
    );

    setSubjectData(r.data);
    // return r.data
  };

  const getTopicDetails = async () => {
    try {
      const r = await axios.get(
        NEW_API_URL + "lessonplan/get-topic-materials/" + topic + "/"
      );

      console.log("topic materials fetch succesful");
      setTopicDetail(r?.data?.topic_detail);
    } catch (error) {
      console.log("Errror fetching topic materials", error);
      try {
        const response = await axios.get(
          NEW_API_URL + "lessonplan/get_topic_detail/" + username + "/" + topic + "/"
        );

        console.log("topic details fetch succesful");
        setTopicDetail(response?.data?.topic_detail);
      } catch (error) {
        console.log("Errror fetching topic details", error);
        throw error;
      }
    }
  };

  useEffect(() => {
    const fetchTopicDetails = async () => {
      getTopicDetails();
    };
    fetchTopicDetails();
  }, []);

  useEffect(() => {
    get_lesson_map_data(username, 1);
  }, [subject, area, topic]);

  const get_map_data = async (username, workspace) => {
    try {
      setIsLoading(true);

      const r = await axios.get(
        NEW_API_URL + "lessonplan/get-concept-list/" + topic
      );

      setIsLoading(false);

      setConceptLists(r.data.concepts);
    } catch (error) {
      setIsLoading(false);
      console.log("error fetching concept list", error);
      //navigate to topicdetail page
      window.location.href = "/learn/" + subject + "/" + area + "/" + topic + "/";
    }

    // r.data.topic_detail["contents"] = r.data.topic_detail.concepts;
  };

  useEffect(() => {
    get_map_data(username, topic);
  }, []);

  console.log("conceptlists--->", conceptLists);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex mt={"10vh"} alignItems={"center"}>
              {/* <ArrowBackIcon
                cursor={"pointer"}
                onClick={() => {
                  navigate(-1);
                }}
                marginRight={"20px"}
                boxSize={"45px"}
              /> */}
              <BackArrow />

              <Box flex={1}>
                <P2xl mb={0} ml={0} mr={0}>
                  <strong>{topicDetail.topic_name}</strong>
                </P2xl>
              </Box>
              <div style={{ width: 50, height: 50, marginRight: 10 }}>
                <CircularProgressbar
                  value={topicDetail.completion_rate}
                  text={`${Math.round(topicDetail.completion_rate, 0)}%`}
                />
              </div>

              {topicDetail.topic_priority === "Low" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#5F79D7"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}
              {topicDetail.topic_priority === "Medium" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#F4B400"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}
              {topicDetail.topic_priority === "High" && (
                <Center
                  px={"5px"}
                  borderRadius={"50px"}
                  backgroundColor={"#FF0E36"}
                  minW={"50px"}
                  minH={"50px"}
                  color={"white"}
                >
                  {topicDetail.topic_priority}
                </Center>
              )}

              {/*<Box ml={"60px"} mt={"16px"}>*/}
              {/*  <MultiLayerDropdown data={subjectData} style={{zIndex:900}} text={"Change Topic"} />*/}
              {/*</Box>*/}
              {/*<Pmd color={"#817D7D"} style={{ margin: 0 }}>*/}
              {/*  <strong>History</strong>*/}
              {/*</Pmd>*/}
            </Flex>
            <hr />

            <Flex mt={20} mb={8} justifyContent={"space-between"}>
              <Pmd>Select a concept below to start</Pmd>
              <Box>
                <ButtonPrimary
                  onClick={() => {
                    window.location.href =
                      "/learn/" + subject + "/" + area + "/" + topic + "/";
                    // setNoteType("All Notes");
                    // setNotesDetail(notesDetailOrigial);
                  }}
                  bg={
                    noteType === "All Notes"
                      ? "claydeck.brand.primary"
                      : "transparent"
                  }
                  mr={"15px"}
                  color={noteType === "All Notes" ? "white" : "#0962A2"}
                >
                  All Materials{" "}
                  <Center
                    ml={"10px"}
                    bg={"white"}
                    borderRadius={"36px"}
                    w={"20px"}
                    h={"20px"}
                  >
                    <Image h={"20px"} src={"/arrowright.png"}></Image>
                  </Center>
                </ButtonPrimary>
              </Box>
            </Flex>
            {isLoading ? (
              <Spinner size={"sm"} color="#0962A2" />
            ) : (
              <Wrap width={"100%"} spacing={"80px"}>
                {conceptLists &&
                  conceptLists.length > 0 &&
                  conceptLists.map((item) => {
                    console.log("items ---->", item);
                    let image = "";

                    if (image !== "") {
                      return;
                    } else {
                      if (item?.completion_rate === 0) {
                        image = "/notes_preview.png";
                      } else if (item?.completion_rate === 100) {
                        image = "/notes_preview.png";
                      } else {
                        image = "/notes_preview.png";
                      }
                    }
                    return (
                      <WrapItem>
                        <NotesCard
                          concept_card
                          mynote={noteType === "My Notes"}
                          read_status={item.read_status}
                          key={item.id}
                          conceptCard={true}
                          completion_rate={item.completion_rate}
                          to={
                            "/learn/" +
                            subject +
                            "/" +
                            area +
                            "/" +
                            topic +
                            "/concepts/" +
                            item.id
                          }
                          shadow
                          w={"180px"}
                          title={item.title}
                          icon={image}
                        />
                      </WrapItem>
                    );
                  })}
                {/*<WrapItem>*/}
                {/*<NotesCard to={isTimeline?'/learn/history/geo/eco/timeline/123':'/learn/history/geo/eco/notes/123'} shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                {/*</WrapItem>*/}
                {/*<WrapItem>*/}
                {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                {/*</WrapItem>*/}
                {/*<WrapItem>*/}
                {/*  <NotesCard shadow w={'180px'} title={'Case study'} icon={'/notes_preview.png'}/>*/}
                {/*</WrapItem>*/}
                {/*<WrapItem>*/}
                {/*  <NotesCard shadow w={'180px'} title={'Mind map'} icon={'/mind_map_preview.png'}/>*/}
                {/*</WrapItem>*/}
                {/*<WrapItem>*/}
                {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                {/*</WrapItem>*/}
              </Wrap>
            )}
          </Box>
        </Flex>
        <CustomStyledModal
          add_type_topic={!isTimeline}
          area={""}
          subject={""}
          topic_name={topicDetail.topic_name}
          topic_id={topic}
          toast={"Note successfully created!"}
          topicDetail={topicDetail}
          successNoteAdd={() => {
            get_map_data(username, topic);
          }}
          add_note
          isOpen={notesModalStates.isOpen}
          onOpen={notesModalStates.onOpen}
          onClose={notesModalStates.onClose}
          title={"Notes"}
          selectedText={""}
        />
        {/*<Flex*/}
        {/*  onClick={() => {*/}
        {/*    notesModalStates.onOpen();*/}
        {/*  }}*/}
        {/*  cursor={"pointer"}*/}
        {/*  w={"80px"}*/}
        {/*  h={"80px"}*/}
        {/*  alignItems={"center"}*/}
        {/*  justifyContent={"center"}*/}
        {/*  bg={"claydeck.brand.primary"}*/}
        {/*  border={"2px solid white"}*/}
        {/*  boxShadow={"0px 4px 4px 0px #00000040"}*/}
        {/*  position={"fixed"}*/}
        {/*  bottom={"80px"}*/}
        {/*  right={"20px"}*/}
        {/*  borderRadius={"50px"}*/}
        {/*  zIndex={10}*/}
        {/*>*/}
        {/*  <Text color={"white"} margin={0} fontSize={"32px"}>*/}
        {/*    +*/}
        {/*  </Text>*/}
        {/*</Flex>*/}
      </Container>
    </>
  );
};

export default TopicConcept;
