import React, { useState, useRef, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Modal,
  position,
  Select,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import { quiz_map } from "../../data/new_generated/quiz_map";
import { quiz_details } from "../../data/new_generated/quiz_details";
import AskBudAccent from "../../Components/AskBudAccent";
import { IoSettingsSharp } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import BackArrow from "../../Components/backArrow";
import conceptData from '../../../src/data/dummyNewsTopicDetail.json'

const QuestionQuiz = (props) => {
  const location = useLocation();
  const { leaderboard } = location.state || {};
  const { topic, subject, area, noteid } = useParams();




  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex
              mt={props.isConceptReader?'0':"10vh"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={4}
              >
                <><BackArrow/>

                  <Box>
                    <P2xl mb={0} ml={0} mr={0}>
                      <strong>Question</strong>
                    </P2xl>
                  </Box></>
              </Box>

            </Flex>

            <hr />

            <Container maxW={"4xl"}       >

              <HeadCard title={'Questions'}>

                {conceptData.mains_questions.map(i=>(
                    <Box padding={'10px'}>
                    <Pmd>Q: {i.title}</Pmd>
                      <hr style={{margin:0}}/>
                    </Box>
                ))}
              </HeadCard>

            </Container>
          </Box>
        </Flex>

      </Container>
    </>
  );
};

export default QuestionQuiz;
