import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

import HeaderLayout from "./Elements/HeaderLayout";

import DesignSheet from "./Pages/DesignSheet";
import LandingPage from "./Pages/Landing/LandingPage";
import SignUp from "./Pages/auth/SignUp";
import SignIn from "./Pages/auth/SignIn";
import PasswordReset from "./Pages/auth/unused/PasswordReset";
import Plans from "./Pages/plans/Plans";
import JoinWorkspace from "./Pages/workspaces/JoinWorkspace";
import SurveryQuestions from "./Pages/survery/SurveyQuestions";
import JoinFeed from "./Pages/feeds/JoinFeed";
import TermAndConditions from "./Pages/Landing/TermAndConditions";
import Feed from "./Pages/Feed/Feed";
import Library from "./Pages/library/Library";
import Books from "./Pages/library/StudyResourceComps/components/Books";
import Notes from "./Pages/library/StudyResourceComps/components/Notes";
import MustReads from "./Pages/library/StudyResourceComps/components/MustReads";
import SavedFeeds from "./Pages/library/StudyResourceComps/components/SavedFeeds";
import MockPaperSubjects from "./Pages/library/ExamPracticeMAterialComps/components/MockPaperSubjects";
import MockPaperChapters from "./Pages/library/ExamPracticeMAterialComps/components/MockPaperChapters";
import MokePaperTestPage from "./Pages/library/ExamPracticeMAterialComps/components/MokePaperTestPage";
import SubjectiveTestComp from "./Pages/library/ExamPracticeMAterialComps/components/SubjectivTestPage";
import PracticeQuizeSubjects from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperSubjects";
import Home from "./Pages/home/Home";
import Tasks from "./Pages/home/Tasks";
import LessonPlan from "./Pages/home/LessonPlan";
import LessonPlanDetails from "./Pages/home/LessonPlanDetails";
import VideoCallNGComponent from "./Components/VideoCallNGComponent";
import MentorWorkspacePage from "./Pages/mentor-workspace/mentor-workspace";
import Formus from "./Pages/forums/forums";
import Compose from "./Pages/compose/compose";
import DetailedForumPage from "./Pages/forums/detailedForumpage";
import SearchedDataPage from "./Pages/search/search";

import { useSelector, useDispatch } from "react-redux";
import VideoCallRTM from "./Components/VideoCallRTM";
import { eventData, roomData } from "./data/data";
import SubQuize from "./Pages/Landing/components/Subjective-Quiz";
import NewsQuiz from "./Pages/Landing/components/News-Quiz";
import SubjectiveTest from "./Pages/library/ExamPracticeMAterialComps/components/SubjectiveTest";
import PracticePaperChapters from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperChapters";
import PracticePaperTestPage from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperTestPage";
import MapView from "./Pages/library/MapView";
import Books2 from "./Pages/library/StudyResourceComps/components/Books2";
import NotesDetail from "./Pages/library/StudyResourceComps/components/NotesDetail";
import Profile from "./Pages/Profile";
import Stats from "./Pages/Stats";
import RecentMCQ from "./Pages/RecentMCQ";
import RecentSub from "./Pages/RecentSub";
import Home2 from "./Pages/home/Home2";
import Tests from "./Pages/home/Tests";
import TopicDetail from "./Pages/home/TopicDetail";
import TopicNote from "./Pages/home/TopicNote";
import NotesReading from "./Pages/home/NotesReading";
import News from "./Pages/home/News";
import Readings from "./Pages/home/Readings";
import NewsReading from "./Pages/home/NewsReading";
import Learn from "./Pages/home/Learn";
import TestsQuiz from "./Pages/home/TestsQuiz";
import TimelineReading from "./Pages/home/TimelineReading";
import NewsTopic from "./Pages/home/NewsTopic";
import LearnTests from "./Pages/home/LearnTests";
import ITools from "./Pages/home/Itools";
import Pmd from "./Components/Typography/Text/Pmd";
import IToolsReading from "./Pages/home/IToolsReading";
import CustomStyledModal from "./Components/Modal/CustomStyledModal";
import TopicReading from "./Pages/home/TopicReading";
import PrivacyPolicy from "./Pages/Landing/PrivacyPolicy";
import ForgotPasswordReset from "./Pages/auth/ForgotPasswordReset";
import MyNotes from "./Pages/home/MyNotes";
import axios from "axios";
import { NEW_API_URL } from "./redux/types";
import Subscription from "./Pages/home/Subscription";
import ButtonPrimary from "./Components/Button/ButtonPrimary";
import { datadogRum } from "@datadog/browser-rum";
import TopicConcept from "./Pages/home/TopicConcept";
import ConceptReading from "./Pages/home/ConceptReading";
import NewsTopicDetail from "./Pages/home/NewsTopicDetail";
import NewsTopicDetailQuestions from "./Pages/home/NewsTopicDetailQuestions";
import QuestionQuiz from "./Pages/home/QuestionQuiz";
import NewsReadingDescription from "./Pages/home/NewsReadingDescription";
import FlowTest from "./Pages/home/FlowTest";
import NotFound from "./Pages/NotFound";
import Room from "./Pages/Room";

// Route path constants
const ROUTES = {
  HOME: "/",
  AUTH: {
    SIGN_UP: "/signup",
    SIGN_IN: "/signin",
    FORGOT_PASSWORD_RESET: "/reset_password/user/reset-password/:uid/:token",
  },
  PUBLIC: {
    TERMS: "/terms",
    PRIVACY: "/privacy-policy",
    SUB_QUIZ: "/sub-quiz",
    NEWS_QUIZ: "/news-quiz",
  },
  PROTECTED: {
    HOME: "/home",
    LIBRARY: "/library",
    PROFILE: "/profile",
    // ... add other protected routes
  }
};

// Auth Guard HOC
const ProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.user.accessToken);
  if (!token) {
    return <Navigate to={ROUTES.AUTH.SIGN_IN} replace />;
  }
  return children;
};

// Public routes (no header, no auth required)
const publicRoutes = [
  {
    path: ROUTES.HOME,
    element: <LandingPage />,
  },
  {
    path: ROUTES.PUBLIC.SUB_QUIZ,
    element: <SubQuize />,
  },
  {
    path: ROUTES.PUBLIC.NEWS_QUIZ,
    element: <NewsQuiz />,
  },
  {
    path: ROUTES.PUBLIC.TERMS,
    element: <TermAndConditions />,
  },
  {
    path: "/tandc", // Redirect old route to new terms route
    element: <Navigate to={ROUTES.PUBLIC.TERMS} replace />,
  },
  {
    path: ROUTES.PUBLIC.PRIVACY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD_RESET,
    element: <ForgotPasswordReset />,
  },
];

// Routes that require header layout
const headerLayoutRoutes = [
  // Auth routes
  {
    path: ROUTES.AUTH.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: ROUTES.AUTH.SIGN_IN,
    element: <SignIn />,
  },
  // Protected routes
  {
    path: ROUTES.PROTECTED.HOME,
    element: <ProtectedRoute><Home2 /></ProtectedRoute>,
  },
  // Library routes
  {
    path: ROUTES.PROTECTED.LIBRARY,
    children: [
      {
        path: "",
        element: <ProtectedRoute><Library /></ProtectedRoute>,
      },
      {
        path: "books",
        element: <ProtectedRoute><Books2 /></ProtectedRoute>,
      },
      {
        path: "notes",
        element: <ProtectedRoute><Notes /></ProtectedRoute>,
      },
      {
        path: "notes/:id",
        element: <ProtectedRoute><NotesDetail /></ProtectedRoute>,
      },
      {
        path: "map",
        element: <ProtectedRoute><MapView /></ProtectedRoute>,
      },
      {
        path: "must-reads",
        element: <ProtectedRoute><MustReads /></ProtectedRoute>,
      },
      {
        path: "saved-feeds",
        element: <ProtectedRoute><SavedFeeds /></ProtectedRoute>,
      },
      {
        path: "mock-papers",
        element: <ProtectedRoute><MockPaperSubjects /></ProtectedRoute>,
      },
      {
        path: "mock-papers/:subject/:id",
        element: <ProtectedRoute><MockPaperChapters /></ProtectedRoute>,
      },
      {
        path: "mock-papers/:subject/:test/:id",
        element: <ProtectedRoute><MokePaperTestPage /></ProtectedRoute>,
      },
      {
        path: "subjective-test",
        element: <ProtectedRoute><SubjectiveTestComp /></ProtectedRoute>,
      },
      {
        path: "subjective-test/:id",
        element: <ProtectedRoute><SubjectiveTest /></ProtectedRoute>,
      },
      {
        path: "practice-quize",
        element: <ProtectedRoute><PracticeQuizeSubjects /></ProtectedRoute>,
      },
      {
        path: "practice-quize/:subject/:id",
        element: <ProtectedRoute><PracticePaperChapters /></ProtectedRoute>,
      },
      {
        path: "practice-quize/:subject/:test/:id",
        element: <ProtectedRoute><PracticePaperTestPage /></ProtectedRoute>,
      },
    ],
  },
  // Learning routes
  {
    path: "/learn",
    children: [
      {
        path: "",
        element: <ProtectedRoute><Learn /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic",
        element: <ProtectedRoute><TopicDetail /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/notes/:noteid",
        element: <ProtectedRoute><NotesReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/concepts/:noteid",
        element: <ProtectedRoute><ConceptReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/news/:noteid",
        element: <ProtectedRoute><NewsReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/timeline/:noteid",
        element: <ProtectedRoute><TimelineReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/timeline",
        element: <ProtectedRoute><TopicNote isTimeline={true} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/news",
        element: <ProtectedRoute><NewsTopic /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/tests/:noteid",
        element: <ProtectedRoute><TestsQuiz /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/tests",
        element: <ProtectedRoute><LearnTests /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/notes_reading/:indext",
        element: <ProtectedRoute><NotesReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/itools/:noteid",
        element: <ProtectedRoute><IToolsReading /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/readings/:noteid",
        element: <ProtectedRoute><TopicReading isTimeline={false} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/itools",
        element: <ProtectedRoute><ITools /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/concepts",
        element: <ProtectedRoute><TopicConcept isTimeline={false} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/notes",
        element: <ProtectedRoute><TopicNote isTimeline={false} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/readings",
        element: <ProtectedRoute><Readings /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/itools",
        element: <ProtectedRoute><ITools /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/concepts",
        element: <ProtectedRoute><TopicConcept isTimeline={false} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/notes",
        element: <ProtectedRoute><TopicNote isTimeline={false} /></ProtectedRoute>,
      },
      {
        path: ":subject/:area/:topic/mynotes",
        element: <ProtectedRoute><MyNotes isTimeline={false} /></ProtectedRoute>,
      },
    ],
  },
  {
    path: "/learn/:subject/:area/:topic/news/:noteid",
    element: <ProtectedRoute><NewsReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/timeline/:noteid",
    element: <ProtectedRoute><TimelineReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/timeline",
    element: <ProtectedRoute><TopicNote isTimeline={true} /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/news",
    element: <ProtectedRoute><NewsTopic /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/tests/:noteid",
    element: <ProtectedRoute><TestsQuiz /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/tests",
    element: <ProtectedRoute><LearnTests /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/notes/:noteid",
    element: <ProtectedRoute><NotesReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/concepts/:noteid",
    element: <ProtectedRoute><ConceptReading /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail",
    element: <ProtectedRoute><NewsTopicDetail /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail/description/:noteid",
    element: <ProtectedRoute><NewsReadingDescription /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail/questions",
    element: <ProtectedRoute><NewsTopicDetailQuestions /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail/questions/prelims/:noteid",
    element: <ProtectedRoute><TestsQuiz /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail/questions/mains/:noteid",
    element: <ProtectedRoute><QuestionQuiz /></ProtectedRoute>,
  },
  {
    path: "/news/topicDetail/timeline/:noteid",
    element: <ProtectedRoute><TimelineReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/notes_reading/:indext",
    element: <ProtectedRoute><NotesReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/itools/:noteid",
    element: <ProtectedRoute><IToolsReading /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/readings/:noteid",
    element: <ProtectedRoute><TopicReading isTimeline={false} /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/itools",
    element: <ProtectedRoute><ITools /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/concepts",
    element: <ProtectedRoute><TopicConcept isTimeline={false} /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/notes",
    element: <ProtectedRoute><TopicNote isTimeline={false} /></ProtectedRoute>,
  },
  {
    path: "/mynotes",
    element: <ProtectedRoute><MyNotes isTimeline={false} /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic/readings",
    element: <ProtectedRoute><Readings /></ProtectedRoute>,
  },
  {
    path: "/learn/:subject/:area/:topic",
    element: <ProtectedRoute><TopicDetail /></ProtectedRoute>,
  },
  {
    path: "/learn",
    element: <ProtectedRoute><Learn /></ProtectedRoute>,
  },
  {
    path: "/tests",
    element: <ProtectedRoute><Tests /></ProtectedRoute>,
  },
  {
    path: "/tests/:noteid",
    element: <ProtectedRoute><TestsQuiz /></ProtectedRoute>,
  },
  {
    path: "/tasks",
    element: <ProtectedRoute><Tasks /></ProtectedRoute>,
  },
  {
    path: "/news/:id",
    element: <ProtectedRoute><NewsReading /></ProtectedRoute>,
  },
  {
    path: "/news",
    element: <ProtectedRoute><News /></ProtectedRoute>,
  },
  {
    path: "/lesson-plan",
    children: [
      {
        path: "",
        element: <ProtectedRoute><LessonPlan /></ProtectedRoute>,
      },
      {
        path: ":id",
        element: <ProtectedRoute><LessonPlanDetails /></ProtectedRoute>,
      },
    ],
  },
  {
    path: "/search",
    element: <ProtectedRoute><SearchedDataPage /></ProtectedRoute>,
  },
  {
    path: "/room",
    element: <ProtectedRoute><Room /></ProtectedRoute>,
  },
  {
    path: "/profile",
    element: <ProtectedRoute><Profile /></ProtectedRoute>,
  },
  {
    path: "/room/:workspace/:roomid",
    element: <ProtectedRoute><VideoCallNGComponent /></ProtectedRoute>,
  },
  {
    path: "/mentor-workspace",
    element: (
      <ProtectedRoute>
        <MentorWorkspacePage roomdata={roomData} eventdata={eventData} />
      </ProtectedRoute>
    ),
  },
  {
    path: "/feeds",
    element: <ProtectedRoute><Feed /></ProtectedRoute>,
  },
  {
    path: "/my-stats",
    children: [
      {
        path: "",
        element: <ProtectedRoute><Stats /></ProtectedRoute>,
      },
      {
        path: "recent-mcq",
        element: <ProtectedRoute><RecentMCQ /></ProtectedRoute>,
      },
      {
        path: "recent-sub",
        element: <ProtectedRoute><RecentSub /></ProtectedRoute>,
      },
    ],
  },
  {
    path: "/forums",
    children: [
      {
        path: "",
        element: <ProtectedRoute><Formus /></ProtectedRoute>,
      },
      {
        path: "compose",
        element: <ProtectedRoute><Compose /></ProtectedRoute>,
      },
      {
        path: ":id/detailedforum",
        element: <ProtectedRoute><DetailedForumPage /></ProtectedRoute>,
      },
    ],
  },
];

// Main router configuration
const router = createBrowserRouter([
  ...publicRoutes,
  {
    element: <HeaderLayout />,
    children: headerLayoutRoutes,
    errorElement: <NotFound />,
  },
]);

const Routes = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const token = useSelector((state) => state.user.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionFound, setSubscriptionFound] = useState(true);
  const { isOpen, onOpen } = useDisclosure();

  // Check subscription status
  const fetchSubscription = async () => {
    if (!username) return;

    try {
      const res = await axios.get(`${NEW_API_URL}community/plans/${username}/`);
      const activeSub = res.data.filter((i) => i.status !== "Inactive");
      setSubscriptionFound(activeSub.length > 0);
    } catch (error) {
      console.error("Subscription check failed:", error);
      setSubscriptionFound(false);
    }
  };

  // Effects
  useEffect(() => {
    if (!subscriptionFound && token) {
      onOpen();
    }
  }, [subscriptionFound, token]);

  useEffect(() => {
    fetchSubscription();
  }, [username]);

  useEffect(() => {
    datadogRum.setUser({
      id: username,
      name: username,
    });
  }, [username]);

  // Clear news date on page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("news_date");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  if (isLoading) {
    return <Spinner size="sm" color="claydeck.brand.primary" />;
  }

  if (!subscriptionFound && token) {
    return (
      <CustomStyledModal
        subscription_new
        username={username}
        subscription
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onOpen}
        title="No Active Subscription found"
      />
    );
  }

  return <RouterProvider router={router} />;
};

export default Routes;
