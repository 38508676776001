import { Box, Center, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Plg from "../../../Components/Typography/Text/Plg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const AuthCarousel = () => {
  const slides = [
    {
      image: "/intro-s4.png",
      text: "Get daily task recommendations and complete milestones to track your progress"
    },
    {
      image: "/intro-s1.png",
      text: "An app that adapts to your learning style and progress"
    },
    {
      image: "/intro-s2.png",
      text: "Learn with interactive notes and correlate topics with visual timelines"
    },
    {
      image: "/intro-s3.png",
      text: "Stay aware of most important current affairs with Claydeck's AI priority"
    }
  ];

  const CarouselIndicator = ({ isSelected, onClick, index }) => (
    <li
      key={index}
      style={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: isSelected ? 'black' : 'rgba(0, 0, 0, 0.3)',
        display: 'inline-block',
        margin: '8px',
        cursor: 'pointer'
      }}
      onClick={onClick}
    />
  );

  return (
    <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Link to="/">
        <Image
          height="80px"
          objectFit="contain"
          src="claydeck_logo_full.png"
          alt="site-logo"
        />
      </Link>

      <Carousel
        autoPlay
        interval={4000}
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        renderIndicator={(onClickHandler, isSelected, index) => (
          <CarouselIndicator
            isSelected={isSelected}
            onClick={onClickHandler}
            index={index}
          />
        )}
      >
        {slides.map((slide, index) => (
          <Box key={index} height="80vh">
            <Center height="80%">
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                style={{ maxHeight: '80%', maxWidth: '40%' }}
              />
            </Center>
            <Plg textAlign="center" mt={4}>
              {slide.text}
            </Plg>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
