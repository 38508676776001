import "./App.css";
import Routes from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_GTAG === "true") {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-5FR72GTJ");
    }
  }, []);
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="296711391711-5qo17nf4tnl6ngikj5ke6ve8b64m0jv7.apps.googleusercontent.com">
        {process.env.REACT_APP_ENABLE_GTM === "true" && (
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-5FR72GTJ"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
        )}
        <Routes />
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
