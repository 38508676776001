import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import InputOutline from "../../Components/Input/InputOutline";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import axios from "axios";
import {NEW_API_URL} from "../../redux/types";
import Psm from "../../Components/Typography/Text/Psm";
import Pxl from "../../Components/Typography/Text/Pxl";
import { AuthCarousel } from "./components/AuthComponents";
import {validateEmail, validatePassword, SCREEN_BREAKPOINT} from "./utils/authUtils";


const FORGOT_PASSWORD_STATES = {
  INITIAL: 0,
  EMAIL_SENT: 1,
  RESET_PASSWORD: 2
};

const ForgotPasswordReset = () => {
  const navigate = useNavigate();

  const {uid,token} = useParams();

  console.log(uid,token,'uid-token')

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentState, setCurrentState] = useState(FORGOT_PASSWORD_STATES.INITIAL);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Clear any existing auth state when accessing reset password page
    localStorage.removeItem('FBIdToken');
    localStorage.removeItem('user');
    sessionStorage.clear();

    console.log("Current URL params:", { uid, token }); // Debug log

    if (uid && token) {
      try {
        // Remove any potential URL encoding issues
        const cleanUid = decodeURIComponent(uid);
        const cleanToken = decodeURIComponent(token);

        console.log("Cleaned params:", { cleanUid, cleanToken }); // Debug log

        // Check if we have valid parameters
        if (cleanUid && cleanToken) {
          setCurrentState(FORGOT_PASSWORD_STATES.RESET_PASSWORD);
        } else {
          console.error("Invalid reset parameters");
          navigate('/');
        }
      } catch (error) {
        console.error("Error processing reset URL:", error);
        alert("Invalid password reset link. Please request a new one.");
        navigate('/');
      }
    }
  }, []);




  const handleSendResetEmail = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      await axios.post(`${NEW_API_URL}user/send-reset-password-email/`, {
        username: email
      });
      setCurrentState(FORGOT_PASSWORD_STATES.EMAIL_SENT);
    } catch (error) {
      setError(error.response?.data?.message || "Failed to send reset email");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    const passwordValidation = validatePassword(newPassword);
    if (!passwordValidation.isValid) {
      setError(passwordValidation.message);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const cleanUid = decodeURIComponent(uid);
      const cleanToken = decodeURIComponent(token);
      const url = `${NEW_API_URL}user/reset-password/${cleanUid}/${cleanToken}/`;
      await axios.post(url, {
        new_password: newPassword,
        confirm_password: confirmPassword
      });

      alert("Password reset successfully");
      navigate('/signin');
    } catch (error) {
      setError(error.response?.data?.message || "Failed to reset password");
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    switch (currentState) {
      case FORGOT_PASSWORD_STATES.EMAIL_SENT:
        return (
          <Box>
            <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
              Email Sent
            </Pxl>
            <Psm color="#CCCCCC" mt={0}>
              We have sent an email to <strong>{email}</strong> with instructions to reset the password
            </Psm>
            <Pmd
              cursor="pointer"
              color="white"
              mt={5}
              mb={0}
              onClick={() => setCurrentState(FORGOT_PASSWORD_STATES.INITIAL)}
            >
              Didn't receive the mail? <span style={{color: '#75BFF5'}}>Resend</span>
            </Pmd>
            <Pmd
              cursor="pointer"
              color="white"
              mt={5}
              mb={0}
              onClick={() => setCurrentState(FORGOT_PASSWORD_STATES.INITIAL)}
            >
              Wrong Email Address? <span style={{color: '#75BFF5'}}>Change Email Address</span>
            </Pmd>
          </Box>
        );

      case FORGOT_PASSWORD_STATES.RESET_PASSWORD:
        return (
          <Box>
            <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
              Create New Password
            </Pxl>
            <Psm color="#CCCCCC" mt={0}>
              Your new password must follow the password policy
            </Psm>

            <Grid gap="32px">
              <GridItem>
                <Pmd color="white" mb="16px">New Password</Pmd>
                <InputOutline
                  type="password"
                  placeholder="Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd color="white" mb="16px">Confirm Password</Pmd>
                <InputOutline
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </GridItem>
            </Grid>

            <ButtonPrimary
              width="100%"
              mt="20px"
              color="white"
              bg="claydeck.brand.primary"
              marginBottom={0}
              isLoading={isLoading}
              onClick={handlePasswordReset}
            >
              Reset Password
            </ButtonPrimary>
          </Box>
        );

      default: // INITIAL state
        return (
          <Box>
            <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
              Forgot Password
            </Pxl>
            <Psm color="#CCCCCC" mt={0}>
              Enter the email address you used to create the account, and we will email you instructions to reset the password
            </Psm>

            <Grid gap="32px">
              <GridItem>
                <Pmd color="white" mb="16px">Email Address</Pmd>
                <InputOutline
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </GridItem>
            </Grid>

            <ButtonPrimary
              width="100%"
              mt="20px"
              color="white"
              bg="claydeck.brand.primary"
              marginBottom={0}
              isLoading={isLoading}
              onClick={handleSendResetEmail}
            >
              Send Email
            </ButtonPrimary>

            <Pxl color="white" mt={5} mb={0} textAlign="left" fontWeight="400">
              Remember Password?{" "}
              <span
                style={{color: '#75BFF5', cursor: 'pointer'}}
                onClick={() => navigate('/signin')}
              >
                Login
              </span>
            </Pxl>
          </Box>
        );
    }
  };

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
    >
      {screenWidth > SCREEN_BREAKPOINT && <AuthCarousel />}
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgColor="claydeck.brand.secondary"
      >
        <Box
          height="100%"
          width={{ base: "100%", lg: "50%" }}
          maxWidth="620px"
          display="flex"
          gap={{ base: "32px", md: "0px" }}
          flexDirection="column"
          justifyContent="space-evenly"
          padding={{ base: "16px", md: "32px", lg: "0px" }}
        >
          {error && (
            <Pmd color="red" mb="16px">
              {error}
            </Pmd>
          )}
          {renderContent()}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ForgotPasswordReset;
