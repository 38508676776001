import React from "react";
import { Box, Flex, Image, LinkBox } from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";
import Pxl from "./Typography/Text/Pxl";
import { Link } from "react-router-dom";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function NotesCard(props) {
  const dumm = () => {};
  function addEllipses(text) {
    // Check if the text is longer than 150 characters
    if (text.length > 30) return text.slice(0, 30) + "...";

    const words = text.split(" ");
    // Check if there are more than 5 words
    if (words.length > 5) return words.slice(0, 5).join(" ") + "...";

    return text; // Return original text if neither condition is met
  }

  if (props.conceptCard) {
    return (
      <Link style={{ position: "relative" }} to={props.to || "#"}>
        <Box
          left={"-10px"}
          top={"-10px"}
          h={"200px"}
          w={"200px"}
          position={"absolute"}
        >
          <CircularProgressbar value={props.completion_rate} text={`10`} />
        </Box>

        <Box
          zIndex={1}
          minH={props.conceptCard ? "180px" : "220px"}
          maxH={props.conceptCard ? "180px" : "220px"}
          position={"relative"}
          onClick={props.onClick || dumm}
          w={props.w || "300px"}
          borderRadius={props.conceptCard ? "200px" : "12px"}
          backgroundColor={"claydeck.white.primary"}
          {...props}
          boxShadow={props.shadow ? "0px 4px 10px 0px #0000001A" : "none"}
          border={"1px solid #B6B6B6"}
          _hover={{
            boxShadow: "0px 8px 20px 0px #0000001A",
            border: "1px solid #0962A2",
          }}
        >
          {props.read_status === "Read" && (
            <Image
              style={{ position: "absolute", right: "10px", top: "10px" }}
              src={"/read_tick.png"}
              height={"20px"}
              width={"20px"}
            />
          )}
          {/*{props.read_status !== 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_circle.png'} height={'20px'} width={'20px'}/>}*/}

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            pt={9}
            pb={5}
            w={"100%"}
          >
            <Image
              src={props.icon || "/notes_preview.png"}
              h={props.conceptCard ? "60px" : "100px"}
            ></Image>
            <Box maxH={"80px"} overflow={"hidden"} maxW={"90%"}>
              <Pxl
                textOverflow={"ellipsis"}
                textAlign={"center"}
                fontSize={"1em"}
                mt={2}
                fontWeight={"400"}
                mb={2}
              >
                {addEllipses(props.title)}
              </Pxl>
            </Box>
          </Flex>

          {/*{*/}
          {/*    props.mynote && <Box zIndex={30} backgroundColor={'black'} h={'100px'} w={'100px'} position={'absolute'} bottom={0} right={0}>*/}
          {/*    E*/}
          {/*    </Box>*/}
          {/*}*/}
        </Box>
      </Link>
    );
  }

  return (
    <Link to={props.to || "#"}>
      <Box
        zIndex={1}
        minH={props.conceptCard ? "180px" : "220px"}
        maxH={props.conceptCard ? "180px" : "220px"}
        position={"relative"}
        onClick={props.onClick || dumm}
        w={props.w || "300px"}
        borderRadius={props.conceptCard ? "200px" : "12px"}
        backgroundColor={"claydeck.white.primary"}
        {...props}
        boxShadow={props.shadow ? "0px 4px 10px 0px #0000001A" : "none"}
        border={"1px solid #B6B6B6"}
        _hover={{
          boxShadow: "0px 8px 20px 0px #0000001A",
          border: "1px solid #0962A2",
        }}
      >
        {props.read_status === "Read" && (
          <Image
            style={{ position: "absolute", right: "10px", top: "10px" }}
            src={"/read_tick.png"}
            height={"20px"}
            width={"20px"}
          />
        )}
        {/*{props.read_status !== 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_circle.png'} height={'20px'} width={'20px'}/>}*/}

        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          pt={9}
          pb={5}
          w={"100%"}
        >
          <Image
            src={props.icon || "/notes_preview.png"}
            h={props.conceptCard ? "60px" : "100px"}
          ></Image>
          <Box maxH={"80px"} overflow={"hidden"} maxW={"90%"}>
            <Pxl
              textOverflow={"ellipsis"}
              textAlign={"center"}
              fontSize={"1em"}
              mt={5}
              fontWeight={"400"}
            >
              {addEllipses(props.title)}
            </Pxl>
          </Box>
        </Flex>

        {/*{*/}
        {/*    props.mynote && <Box zIndex={30} backgroundColor={'black'} h={'100px'} w={'100px'} position={'absolute'} bottom={0} right={0}>*/}
        {/*    E*/}
        {/*    </Box>*/}
        {/*}*/}
      </Box>
    </Link>
  );
}

export default NotesCard;
