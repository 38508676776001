import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useGoogleLogin } from "react-google-login";

import { Box, Grid, GridItem, Checkbox, Image, Center, Flex, Text, HStack, Img } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import { useDispatch, useSelector } from "react-redux";
import { loginUser, signupUser } from "../../redux/actions/userActions";
import { Carousel } from "react-responsive-carousel";
import Plg from "../../Components/Typography/Text/Plg";
import { GoogleLogin } from "@react-oauth/google";
import Psm from "../../Components/Typography/Text/Psm";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
import IntroCarousel from "./unused/IntroCarousel";
import { featureFlags } from "../../featureflags";
import Pxl from "../../Components/Typography/Text/Pxl";
import { AuthCarousel } from "./components/AuthComponents";
import { validateEmail, validateName, validatePassword, SCREEN_BREAKPOINT } from "./utils/authUtils";

const clientId =
  'dummy'; //"105604948407-qgjbl3k27icekteikgjq56tehc4tlnuc.apps.googleusercontent.com"; // to be replaced with actual client id


const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signuploading = useSelector((state) => state.UI.signupLoading);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [tancAcceptence, setTandcAcceptance] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    setError("");
  };

  const validateForm = () => {
    if (!validateName(formData.firstName)) {
      setError("Please enter a valid first name");
      return false;
    }

    if (!validateName(formData.lastName)) {
      setError("Please enter a valid last name");
      return false;
    }

    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }

    const passwordValidation = validatePassword(formData.password);
    if (!passwordValidation.isValid) {
      setError(passwordValidation.message);
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return false;
    }

    if (!tancAcceptence) {
      setError("Please accept the terms and conditions");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (screenWidth < SCREEN_BREAKPOINT) {
      alert("Please open this page on a bigger screen or download our app");
      return;
    }

    if (!validateForm()) {
      return;
    }

    const userData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      username: formData.email,
      email: formData.email,
      password: formData.password,
      user_image: "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/avatar1.png"
    };

    dispatch(signupUser(userData, false, navigate));
  };

  const handleGoogleSuccess = (response) => {
    const profile = JSON.parse(atob(response.credential.split(".")[1]));
    const userData = {
      username: profile.email.toLowerCase(),
      email: profile.email,
      password: profile.email + profile.sub,
      first_name: profile.given_name,
      last_name: profile.family_name,
      idToken: response.credential,
      authProvider: 'google'
    };
    dispatch(loginUser(userData, false, navigate));
  };

  if (!featureFlags.signUpPage) {
    return (
      <Grid
        columns={{ base: 1, lg: 2 }}
        templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
      >
        {screenWidth > SCREEN_BREAKPOINT && <AuthCarousel />}
        <GridItem
          w="100%"
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgColor="claydeck.brand.secondary"
        >
          <Box mt="40vh" display="flex" flexDirection="column" alignItems="center">
            <Pxl color="white" textAlign="center" fontWeight="bold">
              Please get the app to sign up
            </Pxl>
            <Box as="a" href="https://play.google.com/store/apps/details?id=com.arctech.claydeckapp">
              <Image src="./google_play_button.png" alt="Google play button" style={{ width: 200 }} />
            </Box>
            <Link to="/signin">
            <Box display="flex" flexDirection="row" marginTop="24px">
              <Pmd color="#75BFF5">Already have an account?</Pmd>
              <Pmd color="#75BFF5" style={{ fontWeight: 800, marginLeft: '4px' }}>Sign In</Pmd>
            </Box>
          </Link>
          </Box>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
    >
      {screenWidth > SCREEN_BREAKPOINT && <AuthCarousel />}
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgColor="claydeck.brand.secondary"
      >
        <Box
          height="100%"
          width="80%"
          maxWidth="620px"
          display="flex"
          flexDirection="column"
          mt="120px"
        >
          {error && (
            <Pmd color="red" mb="16px">
              {error}
            </Pmd>
          )}

          <Grid gap="24px">
            <GridItem display="flex" justifyContent="space-between" gap="24px">
              <Box width="100%">
                <Pmd color="white" mb="16px">First Name</Pmd>
                <InputOutline
                  type="text"
                  placeholder="John"
                  value={formData.firstName}
                  onChange={handleInputChange("firstName")}
                />
              </Box>
              <Box width="100%">
                <Pmd color="white" mb="16px">Last Name</Pmd>
                <InputOutline
                  type="text"
                  placeholder="Doe"
                  value={formData.lastName}
                  onChange={handleInputChange("lastName")}
                />
              </Box>
            </GridItem>

            <GridItem>
              <Pmd color="white" mb="16px">Email</Pmd>
              <InputOutline
                type="email"
                placeholder="example@email.com"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
            </GridItem>

            <GridItem>
              <Pmd color="white" mb="16px">Password</Pmd>
              <InputPassword
                value={formData.password}
                onChange={handleInputChange("password")}
              />
            </GridItem>

            <GridItem>
              <Pmd color="white" mb="16px">Confirm Password</Pmd>
              <InputPassword
                value={formData.confirmPassword}
                placeholder="Confirm Password"
                onChange={handleInputChange("confirmPassword")}
              />
            </GridItem>
          </Grid>

          <HStack marginTop="18px" alignItems="center">
            <Checkbox
              isChecked={tancAcceptence}
              onChange={(e) => setTandcAcceptance(e.target.checked)}
              mr="8px"
            />
            <p style={{ color: 'white', marginBottom: 0 }}>
              I accept Claydeck's
              <span
                onClick={() => window.open('/terms', 'T&C', 'width=1000,height=400')}
                style={{ color: '#75BFF5', fontWeight: 800, cursor: 'pointer' }}
              > Terms & Conditions</span>
            </p>
          </HStack>

          <ButtonPrimary
            width="100%"
            color="white"
            bg="claydeck.brand.primary"
            borderRadius="50px"
            mt="40px"
            marginBottom={0}
            isLoading={signuploading}
            onClick={handleSubmit}
          >
            Create Account
          </ButtonPrimary>

          <Link to="/signin">
            <Box display="flex" flexDirection="row" marginTop="24px">
              <Pmd color="#75BFF5">Already have an account?</Pmd>
              <Pmd color="#75BFF5" style={{ fontWeight: 800, marginLeft: '4px' }}>Sign In</Pmd>
            </Box>
          </Link>

          <Flex alignItems="center" justifyContent="center" marginTop={3}>
            <hr style={{ width: '40%', border: '1px solid #838383' }} />
            <Psm margin={0} color="white" mx="20px">Or</Psm>
            <hr style={{ width: '40%', border: '1px solid #838383' }} />
          </Flex>

          <Flex justifyContent="center" mt="10px">
            {screenWidth > SCREEN_BREAKPOINT && (
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => console.log('Login Failed')}
              />
            )}
          </Flex>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SignUp;
